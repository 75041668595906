import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@common/utils';

import { makeStyles } from '@material-ui/core/styles';

import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	TextField,
	FormControlLabel,
	FormControl,
	Checkbox,
	FormGroup,
} from '@material-ui/core';

import { Formik } from 'formik';

import { requestFormSchema } from '@schemas/coliving/requestForm';

function RequestSubmitDialog({ headline, question = '', isOpen, actionLabel, handleAction, handleClose, cancelLabel }) {
	const classes = useStyles();
	const tr = useTranslation();

	const initial = {
		name: '',
		surname: '',
		email: '',
		phone: '',
		agreeMarketingInfo: false,
	};

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			fullWidth
			maxWidth={'sm'}>
			<DialogTitle>
				{tr(headline)}
			</DialogTitle>
			<DialogContent className={classes.content}>
				{question !== '' && tr(question)}
				<Formik
					initialValues={initial}
					validationSchema={requestFormSchema}
				>
					{({
						values,
						handleChange,
						handleBlur,
						touched,
						errors,
						setFieldValue,
					}) => {
						const props = (name, initial = '') => ({
							name: name,
							value: typeof values[name] !== 'undefined' ? values[name] : initial,
							onChange: handleChange,
							onBlur: handleBlur,
							error: touched[name] && Boolean(errors[name]),
							helperText: touched[name] ? errors[name] : '',
						});
						return (
							<form onSubmit={(e) => handleAction(e, values)} >
								<Grid container spacing={3} style={{ marginTop: '8px' }}>
									<Grid item xs={12} sm={6}>
										<TextField fullWidth required variant="outlined" label={tr('Name')} {...props('name')} />
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField fullWidth required variant="outlined" label={tr('Last name')} {...props('surname')} />
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField fullWidth required variant="outlined" label={tr('Email')} {...props('email')} />
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField fullWidth required variant="outlined" label={tr('Your phone')} {...props('phone')} />
									</Grid>
									<Grid item xs={12}>
										<FormControl component="fieldset">
											<FormGroup>
												<FormControlLabel className={classes.chekcbox}
													control={<Checkbox color="primary" value={values.company} onChange={() => setFieldValue('company', !values.company)} />}
													label={tr('Company')}
												/>
											</FormGroup>
										</FormControl>
									</Grid>

									{values.company &&
										<>
											<Grid item xs={12} sm={6}>
												<TextField fullWidth variant="outlined" required={values.company} label={tr('Company')} {...props('companyName')} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField fullWidth variant="outlined" label={tr('Company code')} {...props('companyCode')} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField fullWidth variant="outlined" label={tr('Company address')} {...props('companyAddress')} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField fullWidth variant="outlined" label={tr('Company VAT code')} {...props('companyVATCode')} />
											</Grid>
										</>
									}
									<Grid item xs={12} sm={6}>
										<FormControl component="fieldset">
											<FormGroup>
												<FormControlLabel className={classes.chekcbox}
													control={<Checkbox id="mailing" color="primary" value={values.agreeMarketingInfo} onChange={() => setFieldValue('agreeMarketingInfo', !values.agreeMarketingInfo)} />}
													label={(
														<label htmlFor="mailing" className={classes.label}>
															{tr('I agree to get marketing info.')}
														</label>
													)}
												/>
											</FormGroup>
										</FormControl>
									</Grid>
								</Grid>

								<DialogActions className={classes.actions}>
									<Button
										onClick={handleClose}
										variant="outlined"
										style={{ border: '1px solid rgba(0, 5, 58, 0.5)', color: '#00053a' }}
										className={classes.button}>
										{cancelLabel ? tr(cancelLabel) : tr('Cancel')}
									</Button>
									<Button
										style={{ backgroundColor: '#313133', color: '#fff' }}
										type="submit"
										className={classes.button} variant="contained">
										{tr(actionLabel)}
									</Button>
								</DialogActions>
							</form>
						);
					}}
				</Formik>
			</DialogContent>
		</Dialog >
	);
}

RequestSubmitDialog.propTypes = {
	headline: PropTypes.string.isRequired,
	question: PropTypes.string,
	children: PropTypes.node,
	isOpen: PropTypes.bool.isRequired,
	actionLabel: PropTypes.string.isRequired,
	handleAction: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	cancelLabel: PropTypes.string,
	requestInfo: PropTypes.object,
	setRequestInfo: PropTypes.func,
	handleBlur: PropTypes.func,
	touched: PropTypes.object,
	errors: PropTypes.object,
	values: PropTypes.object,
	handleChange: PropTypes.func,
};

export default RequestSubmitDialog;

const useStyles = makeStyles(() => ({
	content: {
		minHeight: '60px',
	},
	actions: {
		justifyContent: 'space-between',
		padding: '16px 0',
	},
	button: {
		padding: '8px 16px',
		minWidth: '100px',
	},
}));
