import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastContainer } from 'react-toastify';

import Privacy from '../../pages/Privacy/Privacy';
import Terms from '../../pages/Terms/Terms';
import Covid from '../../pages/Covid/Covid';
import Layout from '../Layout/Layout';

import { fetchSettings } from '@redux/actions';

import moment from 'moment';

moment.updateLocale("en", {
	week: {
		dow: 1
	}
});

import './image-gallery.css';
import './App.css';

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			await fetchSettings().then(dispatch);
		})();
	}, [dispatch]);

	return (
		<BrowserRouter basename="/book/1">
			<LastLocationProvider>
				<Switch>
					<Route path="/privacy" exact component={Privacy} />
					<Route path="/terms" exact component={Terms} />
					<Route path="/covid" exact component={Covid} />
					<Route component={Layout} />
				</Switch>
				<ToastContainer />
			</LastLocationProvider>
		</BrowserRouter>
	);
}

export default App;
