import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import _ from 'lodash';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import http from '@common/http';
import moment from 'moment';

import { Grid, Typography, TextField, Dialog, DialogContent, DialogActions } from '@material-ui/core';

import CheckCircle from '@material-ui/icons/CheckCircle';

import { Button, Container, Header, ColivingDetail, GoogleMap, Footer } from '@common/components';

import { toast, useDetails, useSessionState, useTranslation, useI18n } from '@common/utils';
import { useSettings } from '@common/hooks';

import {
	Amenities,
	DatePickers,
	HeaderMessages,
	ProductImages,
	ProductOptions,
	RequestSubmitDialog,
	TimePicker,
} from '@components/Coliving';


const getPeriodTitle = (durationPeriodQuantity, durationPeriod) => {
	if (durationPeriodQuantity < 2) {
		if (durationPeriod === 'hours') {
			return 'hour';
		}
		if (durationPeriod === 'days') {
			return 'day';
		}
		if (durationPeriod === 'weeks') {
			return 'week';
		}
		if (durationPeriod === 'months') {
			return 'month';
		}
	} else {
		return durationPeriod;
	}
};

function ColivingDetails({ history, match }) {
	const classes = useStyles();
	const tr = useTranslation();
	const { locale } = useSelector(state => state.locale);
	const { currencySymbol, currencyCode } = useSettings();

	const { id } = match.params;
	const params = qs.parse(history.location.search);
	const [isRequestFormDialogOpen, setIsRequestFormDialogOpen] = useState(false);
	const [isRequestConfirmDialogOpen, setIsRequestConfirmDialogOpen] = useState(false);
	const [requestButton, setRequestButon] = useState({ text: tr('Inquire'), isSent: false });
	const [seconds, setSeconds] = useState(6);

	const { state: bookingId, setState: setBookingId } = useSessionState('booking-id', '');

	const [quantity, setQuantity] = useState(1);

	const [state, setState] = useState({
		...params,
		from: params.from || null,
		to: params.from ? moment(params.from).add(1, 'day').startOf('day').toISOString() : null,
	});

	const [unavailableRecords, setUnavailableRecords] = useState([]);

	const [period, setPeriod] = useState({
		quantity: 1,
		durationPeriod: '',
		durationPeriodQuantity: 1,
	});

	const { details: coliving, isLoading } = useDetails(`/api/kiosks/coworking/products/${id}?${qs.stringify(state)}`, {
		amenities: [],
		asset: '',
		assetCapacity: 0,
		assetPersonCapacity: 0,
		assetType: '',
		description: '',
		i18n: '',
		image: '',
		images: [],
		location: {
			coordinates: '',
			street: '',
			city: '',
			country: '',
			gettingThere: '',
		},
		options: [],
		perks: '',
		personCapacity: 0,
		pricePerDay: 0,
		pricePerDayAfterDiscount: 0,
		summary: '',
		title: '',
		durationPeriodQuantity: 0,
		durationPeriod: '',
		isOnlyInquired: false,
		isPriceVisible: true,
	}, [id, state]);

	const i18n = useI18n(coliving.i18n);
	const title = i18n('title', coliving.title);
	const description = i18n('productDescription', coliving.productDescription);
	const perks = i18n('perks', coliving.perks);

	useEffect(async () => {
		if (!bookingId) {
			return;
		}

		const { data: booking } = await http.get(`/api/kiosks/coworking/bookings/${bookingId}`);

		if (booking?.expired) {
			setBookingId('');
		}
	}, [bookingId]);

	useEffect(async () => {
		if (coliving?.assetType === 'conferenceRoom' && coliving?.durationPeriod === 'hours') {

			const from = moment().toISOString();
			const to = moment(from).add(period?.durationPeriodQuantity * period?.quantity, 'hours').toISOString();

			const payload = {
				conferenceRoomId: coliving.asset,
				from: state?.from || from,
				to: state?.to || to,
			};

			await http.post('/api/kiosks/coworking/products/conference-room/availability', payload)
				.then(({ data: availability }) => {
					setUnavailableRecords(availability);
				}).catch(handleBookingError);
		}
	}, [state]);

	useEffect(() => {
		history.replace({
			search: qs.stringify(state),
		});
	}, [state]);

	useEffect(() => {
		setPeriod({
			...period,
			durationPeriod: coliving.durationPeriod,
			durationPeriodQuantity: coliving.durationPeriodQuantity,
		});
	}, [coliving]);

	const handleBook = async () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({ 'event': 'Buy Now' });
		window.dataLayer.push({
			event: 'begin_checkout',
			ecommerce: {
				currency: currencyCode,
				value: coliving.pricePerDayAfterDiscount || coliving.pricePerDay,
				items: [
					{
						item_id: coliving._id,
						item_name: coliving.title,
						discount: coliving.pricePerDayAfterDiscount ? coliving.pricePerDay - coliving.pricePerDayAfterDiscount : 0,
						price: coliving.total,
						quantity: period.quantity,
					},
				],
			},
		});

		const res = await http.get(`/api/kiosks/coworking/products/${id}?${qs.stringify(state)}`);
		if (!res.data.available) {
			toast({ text: tr('Booking is not available'), type: 'error' });
			return;
		}

		const products = new Array(quantity).fill({
			product: id,
			from: state.from,
			to: state.to,
			quantity: period.quantity,
			durationPeriod: period.durationPeriod,
			productAssetType: res.data.assetType,
		});

		if (bookingId) {
			http.post(`/api/kiosks/coworking/bookings/${bookingId}/products`, { products }).then(() => {
				history.push(`/booking/${bookingId}/?language=${locale}`);
			}).catch(handleBookingError);
		} else {
			http.post('/api/kiosks/coworking/bookings', { products }).then(({ data: bookings }) => {
				setBookingId(bookings._id);
				history.push(`/booking/${bookings._id}/?language=${locale}`);
			}).catch(handleBookingError);
		}

	};

	const handleBookingError = (e) => {
		if (e?.data?.message === 'BookingOneOrMoreProductsUnavailable') {
			toast({ text: `${tr(e.data.message)}: ${e?.data?.extra?.processed || 0}`, type: 'error' });
		} else if (e?.data?.message === 'IncorrectBookingCheckOutDate') {
			toast({ text: tr(e.data.message), type: 'error' });
		} else {
			toast({ text: e?.data?.message || e.message, type: 'error' });
		}
	};

	const handelRequestFormSubmit = async (e, values) => {
		e.preventDefault();

		const { name, surname, email, phone, companyName, companyCode, companyAddress, companyVATCode, agreeMarketingInfo } = values;

		let description = '';

		for (let [key, value] of Object.entries(_.omit(values, ['agreeMarketingInfo']))) {
			if (key && values) {
				if (key === 'companyName') {
					key = 'company name';
				}
				if (key === 'companyCode') {
					key = 'company code';
				}
				if (key === 'companyAddress') {
					key = 'company address';
				}
				if (key === 'companyVATCode') {
					key = 'company VAT code';
				}
				description = description.concat(`${key}: ${value}\n`);
			}
		}

		if (state.from && state.to) {
			description = `${description}${`from: ${moment(state.from).format(period.durationPeriod === 'hours' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')}\n`}${`to: ${moment(state.to).add(period.durationPeriod === 'hours' ? 1 : 0, 'minutes').format(period.durationPeriod === 'hours' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')}\n`}${period.durationPeriod === 'hours' ? `duration: ${Math.ceil(moment(state.to).diff(state.from, 'minutes') / 60)} hours\n` : ''}`;
		}

		const price = coliving.priceType === 'duration' ? coliving.pricePerPeriod * period.quantity : (coliving?.total || 0);

		const updated = { name, surname, email, phone, companyName, companyCode, companyAddress, companyVATCode, agreeMarketingInfo, description: description, product: id, size: price, from: state.from, to: state.to, quantity: period.quantity, durationPeriod: period.durationPeriod };

		try {
			let interval;
			await http.post('/api/kiosks/coworking/products/opportunity', updated);

			toast({ text: tr('Request created') });

			setIsRequestFormDialogOpen(false);

			setRequestButon({ text: tr('Request sent'), isSent: true });

			setIsRequestConfirmDialogOpen(true);

			interval = setInterval(() => {
				setSeconds((prevSeconds) => prevSeconds - 1);
			}, 1000);

			setTimeout(() => {
				clearInterval(interval);
				window.history.back();
			}, 7000);

		} catch (e) {
			toast(e);
		}
	};

	const getNumber = (no) => {
		const str = no.match(/[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/g)?.[0];
		return typeof str !== 'undefined' && str !== '' ? Number(str) : '';
	};

	const getDateInSeconds = (date, offset) => {
		return (60 * moment(date).hours() + Math.ceil(moment(date).minute() / 15) * 15) * 60 - (offset ? 1 : 0);
	};

	const handleChangeQuantity = useCallback((e) => {
		setPeriod({
			...period,
			quantity: getNumber(e.target.value),
		});
	}, [period, setPeriod]);

	useEffect(() => {
		if (coliving?.durationPeriod && coliving?.durationPeriod !== 'hours') {
			const totalDaysToAdd = coliving?.durationPeriodQuantity * period.quantity || 1;
			let toDate = moment(state.from).add(totalDaysToAdd, coliving.durationPeriod).startOf('day');

			setState({
				...state,
				to: moment(toDate).subtract(1, 'ms').toISOString(),
			});
		} else if (coliving?.durationPeriod && coliving.durationPeriod === 'hours') {
			const seconds = getDateInSeconds(state.from);
			setState({
				...state,
				to: moment(state.from).startOf('day').seconds(seconds + (coliving.durationPeriodQuantity * (period?.quantity || 1)) * 3600 - 1).toISOString(),
			});
		}
	}, [period.quantity, coliving.durationPeriod, coliving.durationPeriodQuantity]);

	const handleTimeChange = ({ prop, seconds }) => {
		const date = state[prop] || state.from || moment();
		const time = moment(date).startOf('day').seconds(seconds).toISOString();

		const props = {
			[prop]: time,
		};
		props.to = moment(date).startOf('day').seconds(seconds + (period.durationPeriodQuantity * (period?.quantity || 1)) * 3600 - 1).toISOString();

		setState({
			...state,
			...props,
		});
	};

	const isDateSelected = state.from && state.to ? true : false;
	const isBookingAvailable = isDateSelected && coliving.available;
	const isPriceVisible = coliving?.isOnlyInquired && coliving?.isPriceVisible === false ? false : true;

	const BookOrInquiredButton = () => {
		if (coliving.isOnlyInquired) {
			return <Button wide={true} color="dark" onClick={() => setIsRequestFormDialogOpen(true)} disabled={!isDateSelected || requestButton.isSent}>{tr(requestButton.text)}</Button>;
		}
		if ((coliving.available || coliving.available === undefined)) {
			return <Button wide={true} color="dark" onClick={handleBook} disabled={!coliving.available || period.quantity < 1}>{tr('Book Now')}</Button>;
		} else {
			return <Button wide={true} color="dark" onClick={() => setIsRequestFormDialogOpen(true)} disabled={!isDateSelected || requestButton.isSent}>{tr(requestButton.text)}</Button>;
		}
	};

	return (
		<div className={classes.root}>
			<HeaderMessages />
			<Header>
				<DatePickers dates={state} setDates={setState} period={period} boxes={true} productId={id} productAssetType={coliving?.assetType}/>
			</Header>

			<Container>
				<Grid container spacing={3} style={{ paddingBottom: '100px' }}>
					<Grid item xs={12} md={8}>
						<Typography variant="h2">
							{title}
						</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						{!coliving.pricePerDayAfterDiscount && isPriceVisible && <div className={classes.price}>
							<span className={classes.outline}>{coliving.priceType === 'duration' ? coliving.pricePerPeriod : coliving.pricePerDay} {currencySymbol}/{coliving?.priceType === 'duration' ? `${coliving?.durationPeriodQuantity} ${tr(getPeriodTitle(coliving?.durationPeriodQuantity, coliving?.durationPeriod))}` : tr('day')}</span>
						</div>}
						{coliving.pricePerDayAfterDiscount > 0 && isPriceVisible && <div className={classes.price}>
							&nbsp;<span className={classes.outline} style={{ textDecorationLine: 'line-through', color: 'red' }}>{coliving.pricePerDay} {currencySymbol}</span>
							&nbsp;<span className={classes.outline}>{coliving.priceType === 'duration' ? coliving.pricePerDayAfterDiscount : coliving.pricePerDayAfterDiscount} {currencySymbol}/{coliving?.priceType === 'duration' ? `${coliving?.durationPeriodQuantity} ${tr(getPeriodTitle(coliving?.durationPeriodQuantity, coliving?.durationPeriod))}` : tr('day')}</span>
						</div>}
					</Grid>
					<Grid item xs={12} md={8}>
						<ProductImages images={coliving.images} />

						<ColivingDetail header={tr('Description')} >
							<div dangerouslySetInnerHTML={{ __html: description ? description : '-' }} />
						</ColivingDetail>

						{coliving.amenities && coliving.amenities.length > 0 &&
							<ColivingDetail header={tr('Amenities')}>
								<Amenities amenities={coliving.amenities} large={true} />
							</ColivingDetail>
						}
						<ColivingDetail header={tr('Location')}>
							<>
								{coliving?.i18n[locale]?.location?.street && <div>{coliving?.i18n[locale]?.location?.street}</div>}
								{coliving?.i18n[locale]?.location?.city && <div>{coliving?.i18n[locale]?.location?.city}</div>}
								{coliving?.i18n[locale]?.location?.country && <div>{coliving?.i18n[locale]?.location?.country}</div>}
							</>
						</ColivingDetail>

						{coliving?.i18n[locale]?.location?.coordinates &&
							<div className={classes.map}>
								<GoogleMap coordinates={coliving?.i18n[locale]?.location?.coordinates} />
							</div>
						}

						{coliving?.i18n[locale]?.location?.gettingThere &&
							<ColivingDetail header={tr('Getting here')}>
								<div dangerouslySetInnerHTML={{ __html: coliving?.i18n[locale]?.location?.gettingThere }} />
							</ColivingDetail>
						}

					</Grid>
					<Grid item xs={12} md={4}>
						{isPriceVisible &&
							<Typography variant="h3" style={{ marginBottom: '16px' }}>
								{tr(isBookingAvailable ? 'Refine your booking' : 'Add dates for prices')}
							</Typography>
						}


						<div className={`${perks !== '' ? '' : classes.options}`} dangerouslySetInnerHTML={{ __html: perks ? perks : '-' }} />

						<div className={classes.separator} />

						<div className={classes.options}>
							<ProductOptions coliving={coliving} search={qs.stringify(state)} quantity={quantity} setQuantity={setQuantity} />
						</div>
						{isPriceVisible &&
							<div className={`${classes.total} ${isBookingAvailable ? '' : classes.totalDisabled}`}>
								<div>{tr('Total')}</div>
								{isDateSelected && !isLoading &&
									<div>{coliving.pricePerDayAfterDiscount > 0 ? coliving.pricePerDayAfterDiscount * period.quantity : coliving.pricePerPeriod * period.quantity} {currencySymbol}</div>
								}
							</div>
						}

						<div className={classes.dates}>
							<Grid item xs={8}>
								<DatePickers dates={state} setDates={setState} period={period} setPeriod={setPeriod} productId={id} productAssetType={coliving?.assetType} />
							</Grid>
							{period?.durationPeriod === 'hours' &&
								<Grid item xs={8} className={classes.timers} style={{ marginLeft: '8px' }}>
									<TimePicker
										prop="from"
										dates={state}
										setDates={setState}
										period={period}
										unavailableRecords={unavailableRecords}
										handleTimeChange={handleTimeChange}
									/>
								</Grid>
							}
							<Grid item xs={4} style={{ marginLeft: '8px' }}>
								<TextField
									inputProps={{
										style: { height: '12px' },
									}}
									InputProps={{
										classes: {
											notchedOutline: classes.outlined,
										},
									}}
									value={period.quantity}
									onChange={handleChangeQuantity}
									fullWidth
									variant="outlined"
									label={tr('Quantity')} />
							</Grid>
						</div>

						<BookOrInquiredButton />

						{!coliving.available && !isLoading && isDateSelected && !coliving.isOnlyInquired &&
							<Typography gutterBottom variant="body2" className={classes.error}>{tr('It is not available during selected dates')} {`(${moment(state.from).format('MMM DD')}-${moment(state.from).add(period.durationPeriodQuantity * period.quantity, period.durationPeriod).format('MMM DD')})`}</Typography>
						}
					</Grid>
				</Grid>
				<RequestSubmitDialog
					isOpen={isRequestFormDialogOpen}
					handleClose={() => setIsRequestFormDialogOpen(false)}
					handleAction={handelRequestFormSubmit}
					headline="Request for availability"
					question="Please fill contact form"
					actionLabel="Submit"
				/>
				<Dialog
					open={isRequestConfirmDialogOpen}
					onClose={() => setIsRequestConfirmDialogOpen(false)}
					fullWidth
					maxWidth={'sm'}>
					<DialogContent style={{ minHeight: '200px', display: 'flex', alignItems: 'center', color: '#2e7d32' }}>
						<CheckCircle style={{ fontSize: '36px', marginRight: '12px' }} />
						<Typography variant="h5" style={{ marginBottom: '16px' }}>
							{tr('Thank you, your inquiry has been received and is being processed. Someone will contact you as soon as possible!')}
						</Typography>
					</DialogContent>
					<DialogActions style={{ fontSize: '14px', padding: '0 24px 24px 0' }}>
						{`${tr('Back to main page after')} ${seconds} ${tr('seconds')}`}
					</DialogActions>
				</Dialog >
			</Container>
			<Footer />
		</div >
	);
}

// AvailabilityChecker.propTypes = {
// 	state: PropTypes.object.isRequired,
// 	params: PropTypes.object.isRequired,
// 	setState: PropTypes.func.isRequired,
// 	handleCheckAvailability: PropTypes.func.isRequired,
// };

ColivingDetails.propTypes = {
	history: PropTypes.object,
	match: PropTypes.object,
};

export default ColivingDetails;

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		paddingBottom: theme.spacing(4),
		position: 'relative',
		minHeight: '96vh',
	},
	outlined: {
		border: '1px solid #313133',
	},
	section: {
		marginBottom: theme.spacing(5),
	},
	map: {
		height: '320px',
		marginTop: theme.spacing(-2),
		marginBottom: theme.spacing(5),
		background: theme.palette.custom.background,
	},
	error: {
		marginTop: theme.spacing(2),
		color: theme.palette.error.main,
	},

	price: {
		paddingTop: '10px',
		color: theme.palette.custom.main,
		fontSize: '20px',
		textAlign: 'right',
	},
	outline: {
		fontWeight: 600,
		fontSize: '28px',
	},

	options: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},

	separator: {
		borderTop: `1px solid ${theme.palette.custom.separator}`,
	},

	dates: {
		marginBottom: theme.spacing(2),
		display: 'flex',
	},

	total: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontWeight: 600,
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
		background: theme.palette.custom.background,
	},

	totalDisabled: {
		opacity: 0.5,
	},
	timers: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '3px 8px',
		border: '1px solid #313133',
		borderRadius: '4px',

		[theme.breakpoints.down('sm')]: {
			padding: '2px 0',
			marginBottom: theme.spacing(2),
		},
	},
}));

