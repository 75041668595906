import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import http from '@common/http';

import { Header, Container, Footer } from '@common/components';

import { Banner, Bookings, BookingSummary, BookingExpired } from './components';

import { HeaderMessages, Progress } from '@components/Coliving';

import { Grid, Typography } from '@material-ui/core';

import { useTranslation, toast, useDetails, useSessionState } from '@common/utils';


function ColivingBookings({ history, match }) {
	const classes = useStyles();
	const tr = useTranslation();
	const { locale } = useSelector(state => state.locale);

	const { id } = match.params;
	const [state, setState] = useState(match.params);
	const { setState: setBookingId } = useSessionState('booking-id', '');

	const { details } = useDetails(`/api/kiosks/coworking/bookings/${id}`, {
		createdAt: '',
		expiresAt: '',
		products: [],
		productsGrouped: [],
		total: 0,
		totalBeds: 0,
		totalFees: 0,
		totalProducts: 0,
		totalRooms: 0,
		totalServices: 0,
		updatedAt: '',
		_id: '',
		orders: [],
	}, [state]);

	const removeReservations = async (reservations) => {
		const ids = details.products.filter(p => reservations.indexOf(p.reservation) !== -1).map(p => p._id);

		for (const bookingProductId of ids) {
			await http.delete(`/api/kiosks/coworking/bookings/${state.id}/products/${bookingProductId}`).catch(toast);
		}

		toast({ text: tr('Reservation removed successfully') });

		setBookingId('');

		if (ids.length === details.products.length) {
			history.replace(`/?language=${locale}`);
		} else {
			setState({
				...state,
				updated: new Date().getTime(),
			});
		}
	};

	const bookings = useMemo(() => details.productsGrouped, [details.productsGrouped]);

	return (
		<div className={classes.root}>
			<HeaderMessages />
			<Header>
				<Progress status="booking" id={id} />
			</Header>

			<Container>
				<Grid container spacing={3} style={{ paddingBottom: '100px' }}>
					<Grid container spacing={2} style={{ paddingBottom: '24px' }}>
						<Grid item xs={12}>
							<Typography variant="h1">
								{tr('Your booking details')}
							</Typography>
						</Grid>
					</Grid>

					{details &&
						<Bookings bookings={bookings} removeReservations={removeReservations} />
					}

					<Grid item xs={12} md={4}>
						{details && !details.expired &&
							<BookingSummary
								bookingId={id}
								total={details.total}
								totalFees={details.totalFees}
								totalBeds={details.totalBeds}
								totalRooms={details.totalRooms}
								totalServices={details.totalServices}
								isInitial={details.products.every(p => !p.order)}
							/>
						}
						{details && details.expired &&
							<BookingExpired />
						}
						{(!details || !details.expired) &&
							<Banner />
						}
					</Grid>

					{/*
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Typography variant="h1">
								{tr('Add these services to enjoy your work and stay')}
							</Typography>
						</Grid>
					</Grid>

					<Services services={services} />
				*/}
				</Grid>
			</Container>
			<Footer />
		</div>
	);
}

ColivingBookings.propTypes = {
	history: PropTypes.object,
	match: PropTypes.object,
};

export default ColivingBookings;

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		position: 'relative',
		minHeight: '96vh',
	},
}));

