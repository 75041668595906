import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { Header, Container, Footer } from '@common/components';

import { Bookings, BookingSummary } from './components';

import { HeaderMessages, Progress } from '@components/Coliving';

import { Grid, Typography } from '@material-ui/core';

import { useDetails, useSessionState, useTranslation, useI18n } from '@common/utils';

import { useSettings } from '@common/hooks';

import http from '@common/http';

function Order({ match }) {
	const classes = useStyles();
	const { i18n: _i18, currencyCode } = useSettings();
	const tr = useTranslation();
	const i18n = useI18n(_i18);

	const { id, status } = match.params;

	const bookingConfirmationLeftText = i18n('bookingConfirmationLeftText', _i18.bookingConfirmationLeftText);
	const bookingConfirmationRightText = i18n('bookingConfirmationRightText', _i18.bookingConfirmationRightText);
	const bookingFailureLeftText = i18n('bookingFailureLeftText', _i18.bookingFailureLeftText);
	const bookingFailureRightText = i18n('bookingFailureRightText', _i18.bookingFailureRightText);

	const { details } = useDetails(`/api/kiosks/coworking/bookings/${id}`);
	const { state: bookingId, setState: setBookingId } = useSessionState('booking-id', '');
	const { setState: setBookingGuid } = useSessionState('booking-guid', '');
	const [checkouts, setCheckouts] = useState([]);
	const [cumulativeCheckout, setCumulativeCheckout] = useState();
	const [uninvoicedTotal, setUninvoicedTotal] = useState(0);
	const [isInitial, setIsInitial] = useState(true);

	const isPaymentFailed = status === 'failure';

	if (isPaymentFailed && bookingId) {
		setBookingId('');
		setBookingGuid('');
	}

	useEffect(() => {
		if (!details) { return; }

		(async () => {
			const checkoutResponses = await Promise.all(details.orders.map(async o => await http.get(`/api/kiosks/coworking/checkouts/${o._id}`)));
			const checkouts = checkoutResponses.map(r => r.data);
			setCheckouts(checkouts);
		})().catch(console.error);
	}, [details, setCheckouts]);

	useEffect(() => {
		setCumulativeCheckout(checkouts.reduce((p, c) => ({
			pricing: {
				total: p.pricing.total + c.pricing.total,
				totalVAT: p.pricing.totalVAT + c.pricing.totalVAT,
				discountWithCodeInfo: [...p.pricing.discountWithCodeInfo, c.pricing.discountWithCodeInfo],
			},
			products: [...p.products, ...c.products],
		}), {
			pricing: {
				total: 0,
				totalVAT: 0,
				discountWithCodeInfo: [],
			},
			products: [],
		}));
	}, [checkouts, setCumulativeCheckout]);

	useEffect(() => {
		if (!details) { return; }

		const uninvoicedBookingProducts = details.products.filter(p => !p.order);
		const uninvoicedTotal = uninvoicedBookingProducts.reduce((p, c) => p + c.total, 0);
		const isInitial = uninvoicedBookingProducts.length === details.products.length;

		setUninvoicedTotal(uninvoicedTotal);
		setIsInitial(isInitial);
	}, [details, setUninvoicedTotal, setIsInitial]);

	useEffect(() => {
		if (status !== 'success' || !cumulativeCheckout || !id) { return; }
		if (cumulativeCheckout.pricing.total === 0) { return; }

		const purchaseEvent = {
			event: 'purchase',
			transactionId: id,
			transactionTotal: cumulativeCheckout.pricing.total,
			transactionTax: cumulativeCheckout.pricing.totalVAT,
			transactionProducts: cumulativeCheckout.products.map(p => ({
				sku: p.sku,
				name: p.title,
				category: p.category,
				price: p.price,
				quantity: p.quantity,
			})),
			ecommerce: {
				transaction_id: id,
				value: cumulativeCheckout.pricing.total,
				tax: cumulativeCheckout.pricing.totalVAT,
				currency: currencyCode,
				items: cumulativeCheckout.products.map(p => ({
					item_id: p._id,
					item_name: p.title,
					discount: p.discount,
					price: p.price,
					quantity: p.quantity,
				})),
			},
		};

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(purchaseEvent);
	}, [status, cumulativeCheckout, id]);

	if (!details || !cumulativeCheckout) {
		return null;
	}

	return (
		<div className={classes.root}>
			<HeaderMessages />
			<Header>
				<Progress status={isPaymentFailed ? 'cancellation' : 'confirmation'} />
			</Header>

			<Container>
				<Grid container spacing={3} style={{ paddingBottom: '100px' }}>
					<Grid item xs={12} md={6}>

						{isPaymentFailed &&
							<>
								<Typography variant="h3" className={classes.headline}>
									{tr('Failed reservation')} #{details?.orders[0]?.number}
								</Typography>

								<div dangerouslySetInnerHTML={{ __html: bookingFailureLeftText }} />
							</>
						}

						{!isPaymentFailed && bookingConfirmationLeftText &&
							<>
								<Typography variant="h3" className={classes.headline}>
									{tr('Your booking reservation')} #{details?.orders[0]?.number}
								</Typography>

								<div dangerouslySetInnerHTML={{ __html: bookingConfirmationLeftText }} />
							</>
						}

					</Grid>

					<Grid item xs={12} md={6}>
						<div className={isPaymentFailed || (!isPaymentFailed && bookingConfirmationRightText) ? classes.box : ''}>
							{isPaymentFailed &&
								<>
									<Typography variant="h3" className={classes.headline}>
										{tr('Your payment failed')}
									</Typography>

									<div dangerouslySetInnerHTML={{ __html: bookingFailureRightText }} />
								</>
							}

							{!isPaymentFailed && bookingConfirmationRightText &&
								<>
									<Typography variant="h3" className={classes.headline}>
										{tr('Upon arrival')}
									</Typography>

									<div dangerouslySetInnerHTML={{ __html: bookingConfirmationRightText }} />
								</>
							}
						</div>
					</Grid>

					<Grid container spacing={6} style={{ paddingBottom: '24px' }}>
						<Grid item xs={12}>
							<Typography variant="h3">
								{tr('Booking details')}
							</Typography>
						</Grid>
					</Grid>

					{details &&
						<>
							<Bookings bookings={details.productsGrouped} />

							<Grid item xs={12} md={4} style={{ paddingTop: '4px' }}>
								<BookingSummary
									bookingId={details._id}
									total={cumulativeCheckout.pricing.total}
									totalBeds={details.totalBeds}
									totalRooms={details.totalRooms}
									totalServices={details.totalServices}
									totalFees={details.totalFees}
									discounts={cumulativeCheckout.pricing.discountWithCodeInfo}
									uninvoicedTotal={uninvoicedTotal}
									isInitial={isInitial}
								/>
							</Grid>
						</>
					}
				</Grid>
			</Container>
			<Footer />
		</div>
	);
}

Order.propTypes = {
	match: PropTypes.object,
};

export default Order;

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		position: 'relative',
		minHeight: '96vh',
	},
	headline: {
		marginBottom: theme.spacing(3),
	},
	box: {
		padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(2)}px `,
		backgroundColor: theme.palette.custom.background,
	},
	link: {
		color: theme.palette.primary.dark,
		borderBottom: '1px solid',
		textDecoration: 'none',
		borderBottomColor: theme.palette.primary.light,

		'&:hover': {
			borderBottomColor: 'transparent',
		},
	},
}));

