import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import { Button } from '@common/components';

import { useTranslation } from '@common/utils';
import { useSettings } from '@common/hooks';
import { Link } from 'react-router-dom';

function Summary({ total, totalFees, totalBeds, totalRooms, totalServices, bookingId, discounts, uninvoicedTotal, isInitial }) {
	const classes = useStyles();
	const tr = useTranslation();
	const { currencySymbol } = useSettings();
	const { locale } = useSelector(state => state.locale);

	const trackProceedToCheckout = () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ 'event': 'Proceed to Checkout' });
	};

	return (
		<div className={classes.summary}>
			<Typography variant="h3">
				{tr('Summary')}
			</Typography>
			<div className={classes.rows}>

				{totalRooms > 0 &&
					<>
						<div className={classes.row}>
							<div>{tr('Rooms')}</div>
							<div>{totalRooms} {currencySymbol}</div>
						</div>
						<div className={classes.separator} />
					</>
				}
				{totalBeds > 0 &&
					<>
						<div className={classes.row}>
							<div>{tr('Beds')}</div>
							<div>{totalBeds} {currencySymbol}</div>
						</div>
						<div className={classes.separator} />
					</>
				}
				{totalFees > 0 &&
					<>
						<div className={classes.row}>
							<div>{tr('Fees')}</div>
							<div>{totalFees} {currencySymbol}</div>
						</div>
						<div className={classes.separator} />
					</>
				}
				<div className={classes.row}>
					<div>{tr('Services')}</div>
					<div>{totalServices} {currencySymbol}</div>
				</div>

				<div className={classes.separator} />

				{discounts.filter(d => d.code && d.appliedAmount >= 0).map((d, i) => <div key={i} className={`${classes.row} ${classes.total}`}>
					<div>
						{tr(`Discount (${d.code})`)}
					</div>
					<div>
						&minus;{d.appliedAmount} {currencySymbol}
					</div>
				</div>)}

				<div className={`${classes.row} ${classes.total}`}>
					<div>{tr(!isInitial && uninvoicedTotal > 0 ? 'Total paid' : 'Total')}</div>
					<div>{total} {currencySymbol}</div>
				</div>

				{!isInitial && uninvoicedTotal > 0 && <div className={`${classes.row} ${classes.total}`}>
					<div>{tr('Total due')}</div>
					<div>{uninvoicedTotal} {currencySymbol}</div>
				</div>}
			</div>

			{(isInitial || uninvoicedTotal > 0) && <Button
				wide={true} color="primary"
				component={Link}
				to={`/checkout/${bookingId}/?language=${locale}`}
				onClick={trackProceedToCheckout}
			>
				{tr('Proceed to checkout')}
			</Button>}
		</div>
	);
}

Summary.propTypes = {
	total: PropTypes.number.isRequired,
	totalFees: PropTypes.number.isRequired,
	totalBeds: PropTypes.number.isRequired,
	totalRooms: PropTypes.number.isRequired,
	totalServices: PropTypes.number.isRequired,
	bookingId: PropTypes.string.isRequired,
	discounts: PropTypes.array,
	uninvoicedTotal: PropTypes.number,
	isInitial: PropTypes.bool,
};

Summary.defaultProps = {
	discounts: [],
	uninvoicedTotal: 0,
	isInitial: true,
};

export default Summary;

const useStyles = makeStyles(theme => ({
	summary: {
		marginBottom: theme.spacing(5),
		padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
		borderRadius: '4px',
		backgroundColor: theme.palette.custom.background,
	},
	rows: {
		padding: `${theme.spacing(2)}px 0`,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: `${theme.spacing(2)}px 0`,
	},
	total: {
		fontWeight: 600,
	},
	separator: {
		height: '1px',
		backgroundColor: theme.palette.custom.separator,
	},
}));
