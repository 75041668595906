import React from 'react';
import { makeStyles } from '@material-ui/core';

import { HeaderMessages } from '@components/Coliving';
import { Header, Container, Footer } from '@common/components';

import { useSettings } from '@common/hooks';

const Terms = () => {
	const classes = useStyles();

	const { termsAndConditions } = useSettings();

	return (
		<div className={classes.root}>
			<HeaderMessages />
			<Header />

			{termsAndConditions ?
				<Container>
					<div style={{ paddingBottom: '100px' }} dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
				</Container>
				:
				<Container>
					<h1 style={{ paddingBottom: '100px' }}>RULES FOR CO-WORKING SERVICES</h1>
				</Container>
			}
			<Footer/>
		</div>
	);
};

export default Terms;

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		position: 'relative',
		minHeight: '96vh',
	},
	item: {
		position: 'relative',
		paddingLeft: theme.spacing(5),

		'&::before': {
			content: 'attr(data-no)',
			position: 'absolute',
			left: 0,
			top: 0,
		},
	},
	section: {
		fontWeight: 'bold',
	},
	sub: {
		marginLeft: theme.spacing(5),
		paddingLeft: theme.spacing(7),
	},
}));
