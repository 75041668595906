import React from 'react';

import { SvgIcon } from '@material-ui/core';

function GalleryRight(props) {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M9.002,18c-0.272,0-0.544-0.11-0.742-0.327 c-0.371-0.409-0.341-1.042,0.068-1.413L13.023,12L8.328,7.74C7.919,7.369,7.889,6.736,8.26,6.327 C8.633,5.919,9.268,5.889,9.676,6.26L16,12l-6.324,5.74C9.484,17.914,9.242,18,9.002,18"
			/>
		</SvgIcon>
	);
}

export default GalleryRight;
