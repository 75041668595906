import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import BookingCard from './BookingCard';

function Bookings({ bookings = [], removeReservations }) {

	return (
		<Grid
			container
			item
			spacing={2}
			xs={12} md={8}
			alignContent='space-between'
		>
			<Grid container spacing={3}>
				{bookings.map((booking, i) => {
					return (
						<Grid key={`booking-${i}`} item xs={12} md={bookings.length > 1 ? 6 : 8}>
							<BookingCard booking={booking} removeReservations={removeReservations} />
						</Grid>
					);
				})}
			</Grid>
		</Grid>
	);
}

Bookings.propTypes = {
	bookings: PropTypes.array,
	removeReservations: PropTypes.func,
};

export default Bookings;
