import { createTheme } from '@material-ui/core';

const palette = {
	primary: {
		light: '#949494', // (Grey Icons, text)
		main: '#006CFF',
		dark: '#313133',
	},
	custom: {
		main: '#000091', // Customer specific color, (e.g. logo)
		separator: '#DCDCDC', 	// (Separation Lines)
		borders: '#EAEAEA', 	  // (Components borders)
		background: '#F7F7F7',  // (Text backgrounds)
	},
};

export default createTheme({
	palette: {
		primary: {
			light: palette.primary.light,
			main: palette.primary.main,
			dark: palette.primary.dark,
		},
		custom: palette.custom,
		background: {
			default: '#FFF',
		},
	},
	typography: {
		fontFamily: '\'Poppins\', sans-serif',
		h1: {
			fontWeight: 600,
			fontSize: '2rem',
			lineHeight: '2.5rem',
		},
		h2: {
			fontWeight: 600,
			fontSize: '1.5rem',
			lineHeight: '2rem',

			'@media (min-width: 600px)': {
				fontSize: '2rem',
				lineHeight: '2.5rem',
			},
		},
		h3: {
			fontWeight: 600,
			fontSize: '1.25rem',
			lineHeight: '1.875rem',

			'@media (min-width: 600px)': {
				fontSize: '1.5rem',
				lineHeight: '2rem',
			},
		},
		h4: {
			fontWeight: 600,
			fontSize: '1.25rem',
			lineHeight: '1.875rem',
		},
		h5: {
			fontWeight: 600,
			fontSize: '1.125rem',
			lineHeight: '1.5rem',
		},
		body1: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
		},
		body2: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: '1.5rem',
			color: palette.primary.dark,
		},
	},
	overrides: {
		// MuiInput: {
		// 	root: {
		// 		fontSize: '15px',
		// 		fontWeight: '500',
		// 	},
		// },
	},
});
