import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { Amenity } from '@components/Coliving';

function Amenities({ amenities = [], max, large }) {
	const classes = useStyles();

	const list = max ? amenities.slice(0, max) : amenities;

	return (
		<div className={classes.container}>
			{list.map((amenity, i) => (
				<Amenity key={`amenity-${i}`} amenity={amenity} large={large} />
			))}
		</div>
	);
}

Amenities.propTypes = {
	amenities: PropTypes.array.isRequired,
	max: PropTypes.number,
	large: PropTypes.bool,
};

export default Amenities;

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		margin: `0 -${theme.spacing(2)}px`,
	},
}));
