import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as qs from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Typography, CardContent, CardActionArea } from '@material-ui/core';

import { useTranslation, useI18n } from '@common/utils';
import { useSettings } from '@common/hooks';
import { Amenities } from '@components/Coliving';

const ColivingCardProps = ({ personCapacity }) => {
	const classes = useStyles();
	const tr = useTranslation();

	return (
		<Typography variant="body2" className={classes.props}>
			{personCapacity && <span className={classes.prop}>{personCapacity} {tr(personCapacity > 1 ? 'guests' : 'guest')}</span>}
			<span className={classes.prop}>{tr('access to coworking')}</span>
		</Typography>
	);
};

const ColivingCardPrice = ({ pricePerDay, pricePerPeriod, priceType, durationPeriod, durationPeriodQuantity, pricePerDayAfterDiscount }) => {
	const classes = useStyles();
	const tr = useTranslation();
	const { currencySymbol } = useSettings();

	const getPeriodTitle = () => {
		if (durationPeriodQuantity < 2) {
			if (durationPeriod === 'hours') {
				return 'hour';
			}
			if (durationPeriod === 'days') {
				return 'day';
			}
			if (durationPeriod === 'weeks') {
				return 'week';
			}
			if (durationPeriod === 'months') {
				return 'month';
			}
		} else {
			return durationPeriod;
		}
	};

	return (
		priceType === 'duration' ?
			<>
				{!pricePerDayAfterDiscount && <Typography variant="body2" className={classes.price}>
					{pricePerPeriod} {currencySymbol}
					<span className={classes.currency}> {`/ ${durationPeriodQuantity} ${tr(getPeriodTitle())}`}</span>
				</Typography>}
				{pricePerDayAfterDiscount > 0 && <Typography variant="body2" className={classes.price}>
					<span style={{ textDecorationLine: 'line-through', color: 'red' }}>{pricePerPeriod} {currencySymbol}</span>&nbsp;
					<span>{pricePerDayAfterDiscount} {currencySymbol}</span>
					<span className={classes.currency}> {`/ ${durationPeriodQuantity} ${tr(getPeriodTitle())}`}</span>
				</Typography>}
			</>
			:
			<>
				{!pricePerDayAfterDiscount && <Typography variant="body2" className={classes.price}>
					{pricePerDay} {currencySymbol}<span className={classes.currency}>{`${tr('/day')}`}</span>
				</Typography>}
				{pricePerDayAfterDiscount > 0 && <Typography variant="body2" className={classes.price}>
					<span style={{ textDecorationLine: 'line-through', color: 'red' }}>{pricePerDay} {currencySymbol}</span>&nbsp;
					<span>{pricePerDayAfterDiscount} {currencySymbol}</span>
					<span className={classes.currency}>{`${tr('/day')}`}</span>
				</Typography>}
			</>
	);
};

function ColivingCard({ coliving, filter }) {
	const classes = useStyles();
	const i18n = useI18n(coliving.i18n);

	const { currencyCode } = useSettings();

	const title = i18n('title', coliving.title);
	const summary = i18n('summary', coliving.summary);
	const isPriceVisible = coliving?.isOnlyInquired && coliving?.isPriceVisible === false ? false : true;
	const isDisabled = coliving.available === false && coliving?.isOnlyInquired === false ? true : false;

	const trackItemViews = () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'view_item',
			ecommerce: {
				currency: currencyCode,
				value: coliving.pricePerDayAfterDiscount || coliving.pricePerDay,
				items: [
					{
						item_id: coliving._id,
						item_name: coliving.title,
						discount: coliving.pricePerDayAfterDiscount ? coliving.pricePerDay - coliving.pricePerDayAfterDiscount : 0,
						price: coliving.pricePerDay,
						quantity: 1,
					},
				],
			},
		});
	};

	return (
		<Link onClick={trackItemViews} to={`/${coliving._id}?${qs.stringify(filter)}`} className={classes.link}>
			<Card className={`${classes.card} ${isDisabled ? classes.disabled : ''}`}>
				<CardActionArea className={classes.area}>
					{coliving.image && <CardMedia className={classes.media} image={`/images/${coliving.image}`} title="" />}
					{!coliving.image && <div className={classes.media} />}
					<CardContent className={classes.content}>
						<div className={classes.header}>
							{isPriceVisible &&
								<ColivingCardPrice
									pricePerDay={coliving.pricePerDay}
									pricePerPeriod={coliving.pricePerPeriod}
									priceType={coliving.priceType}
									durationPeriod={coliving?.durationPeriod || ''}
									durationPeriodQuantity={coliving?.durationPeriodQuantity || 0}
									pricePerDayAfterDiscount={coliving.pricePerDayAfterDiscount}
								/>
							}
							<Typography gutterBottom variant="h3" component="h3" className={classes.title}>
								{title}
							</Typography>
						</div>
						<Typography variant="body2" dangerouslySetInnerHTML={{ __html: summary ? summary : '' }} />

					</CardContent>
					{coliving.amenities && coliving.amenities.length > 0 &&
						<CardContent className={classes.footer}>
							<Amenities amenities={coliving.amenities} max={4} />
						</CardContent>
					}
				</CardActionArea>
			</Card>
		</Link>
	);
}

ColivingCard.propTypes = {
	coliving: PropTypes.object.isRequired,
	filter: PropTypes.object,
};

ColivingCardProps.propTypes = {
	personCapacity: PropTypes.number,
};

ColivingCardPrice.propTypes = {
	pricePerDay: PropTypes.number,
	pricePerPeriod: PropTypes.number,
	priceType: PropTypes.string,
	durationPeriodQuantity: PropTypes.number,
	pricePerDayAfterDiscount: PropTypes.number,
	durationPeriod: PropTypes.string,
};

export default ColivingCard;

const useStyles = makeStyles(theme => ({
	link: {
		textDecoration: 'none',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	title: {
		flex: '1 1 auto',
	},
	card: {
		borderRadius: '4px',
		border: `1px solid ${theme.palette.custom.borders}`,
		boxShadow: 'none',
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
	},
	area: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
	},
	header: {
		overflow: 'hidden',
		// [theme.breakpoints.up('md')]: {
		// 	display: 'flex',
		// 	flexDirection: 'row',
		// },
	},

	content: {
		width: '100%',
		flex: '1 1 auto',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,

		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(2)}px`,
		},
	},
	footer: {
		borderTop: '1px solid',
		borderTopColor: theme.palette.custom.separator,
		padding: 0,
		margin: `0 ${theme.spacing(3)}px`,
		alignSelf: 'stretch',
	},
	media: {
		width: '100%',
		height: '248px',
		backgroundColor: theme.palette.custom.background,
	},
	props: {
		display: 'flex',
		marginBottom: theme.spacing(2),

		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	prop: {
		padding: `5px ${theme.spacing(2)}px`,
		border: `1px solid ${theme.palette.custom.borders}`,
		color: theme.palette.primary.light,
		borderRadius: '18px',
		whiteSpace: 'nowrap',
		fontSize: '14px',

		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
			overflow: 'hidden',
			textOverflow: 'hidden',
		},

		'&:nth-child(n+2)': {
			[theme.breakpoints.down('sm')]: {
				marginTop: theme.spacing(1),
			},
			[theme.breakpoints.up('md')]: {
				marginLeft: theme.spacing(2),
			},
		},
	},
	price: {
		float: 'right',
		padding: '4px 0 0 8px',
	},
	currency: {
		color: theme.palette.primary.light,
		paddingLeft: '4px',
	},
	disabled: {
		opacity: 0.4,
	},
}));
