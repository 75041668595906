import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import { Button } from '@common/components';

import { useTranslation } from '@common/utils';
import { Link } from 'react-router-dom';

function BookingExpired() {
	const classes = useStyles();
	const tr = useTranslation();

	return (
		<div className={classes.banner}>
			<Typography variant="h3" className={classes.error}>
				{tr('Your booking expired')}
			</Typography>
			<div className={classes.action}>
				<Button color="dark" style={{ minWidth: '240px' }} component={Link} to="/">{tr('Search for Room')}</Button>
			</div>
			<Typography variant="body2" className={classes.note}>
				{tr('We hold bookings for 15 minutes.')}
			</Typography>
		</div>
	);
}

export default BookingExpired;

const useStyles = makeStyles(theme => ({
	banner: {
		border: `1px solid ${theme.palette.custom.borders}`,
		borderRadius: '4px',
		marginBottom: theme.spacing(5),
		padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
		textAlign: 'center',
	},
	error: {
		color: theme.palette.error.main,
	},
	action: {
		padding: theme.spacing(3),
	},
	note: {
		color: theme.palette.primary.light,
	},
}));
