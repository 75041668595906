import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ColivingList, ColivingDetails, ColivingBookings, ColivingCheckout, ColivingOrder } from '../../pages/Coliving';

import ScrollToTop from './ScrollToTop';

export const routeCoworkingOrder = '/order/:id/:status?';

const Router = () => (<>
	<ScrollToTop />
	<Switch>
		<Route path={routeCoworkingOrder} component={ColivingOrder} />
		<Route path="/checkout/:id" component={ColivingCheckout} />
		<Route path="/booking/:id" component={ColivingBookings} />
		<Route path="/:id" component={ColivingDetails} />
		<Route path="/" exact component={ColivingList} />
		<Route render={() => 'Not Found'} />
	</Switch>
</>);


export default Router;
