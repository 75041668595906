import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';

import { HeaderMessages } from '@components/Coliving';
import { Header, Container, Footer } from '@common/components';

import { useSettings } from '@common/hooks';

const Privacy = () => {
	const classes = useStyles();

	const { privacyPolicy } = useSettings();

	return (
		<div className={classes.root}>
			<HeaderMessages />
			<Header />

			{privacyPolicy ?
				<Container>
					<div style={{ paddingBottom: '100px' }} dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
				</Container>
				:
				<Container>
					<h1 style={{ paddingBottom: '100px' }}>PRIVACY AND COOKIE POLICY</h1>
				</Container>
			}

			<Footer />
		</div >
	);
};

export default Privacy;

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		position: 'relative',
		minHeight: '96vh',
	},
}));
