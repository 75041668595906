import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { Container as MaterialContainer } from '@material-ui/core';

function Container({ children }) {
	const classes = useStyles();

	return (
		<MaterialContainer className={classes.container} maxWidth={false}>
			{children}
		</MaterialContainer>
	);
}

Container.propTypes = {
	children: PropTypes.node,
};

export default Container;

const useStyles = makeStyles(theme => ({
	container: {
		maxWidth: '1176px',
		[theme.breakpoints.down('sm')]: {
			overflow: 'hidden',
		},
	},
}));

