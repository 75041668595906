import React from 'react';
import { makeStyles } from '@material-ui/core';

import { HeaderMessages } from '@components/Coliving';
import { Header, Container, Button, Footer } from '@common/components';

import { Link } from 'react-router-dom';

const Covid = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<HeaderMessages />
			<Header />

			<Container>
				<h1>FREE CANCELLATION!</h1>
				<h3>Book with confidence – flexible cancellation</h3>
				<ul>
					<li>If your booking gets cancelled due to local government COVID-19 restrictions – we will refund full booking amount</li>
					<li>Otherwise, all guests are entitled to free, no questions asked, cancellation 48 hours before your booking</li>
				</ul>

				<br />

				<h1>COVID 19</h1>
				<p>We are doing everything we can to keep our space COVID free & maintain the highest standard of hygiene & cleanliness. The shared spaces are still open however with strict protocol - in line with Government advice. Our onsite cleaners continue to sanitise the communal spaces daily and our community managers are available  via our APP to help & deal with all requests.</p>

				<br />
				<p>
					<Button color="dark" component={Link} to="/">{'Back'}</Button>
				</p>
			</Container>
			<Footer />
		</div >

	);
};

export default Covid;

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		position: 'relative',
		minHeight: '96vh',
	},
}));
