import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, matchPath } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import http from './http';
import { toast as showToast } from 'react-toastify';
import { css } from 'glamor';
import * as qs from 'query-string';
import { debounce } from 'lodash';
// import { updateFilter } from '@redux/actions';

import locales from '../locales';
import Toast from './components/Toast/Toast';
import 'react-toastify/dist/ReactToastify.css';

const dic = {
	'BookingOneOrMoreProductsUnavailable': 'Available beds in room',
	'CoworkingOrConferenceRoomBookingOneOrMoreProductsUnavailable': 'No available room',
	'UnexpectedError': 'Oops, we messed up. We are deeply sorry, but sometimes things just fall apart. You might want to try again. Please? :)',
	'DuplicateOrder': 'It seems that your order has been submitted already! We don\'t want to charge you for more than you have ordered already :)',
	'BookingExpired': 'It seems that your booking has expired, please, start over.',
};

export const tr = key => dic[key] || key;

export const useTranslation = () => {
	const { locale } = useSelector(state => state.locale);

	const translate = key => {
		if (!key) {
			return;
		}

		const translation = locales[locale]?.[key];

		if (!translation) {
			// console.trace(`Translation missing: ${key}`); //eslint-disable-line
			return key;
		}

		return translation;
	};

	return translate;
};


export const useI18n = (obj) => {
	const { locale } = useSelector(state => state.locale);

	const translate = (key, fallback = '') => {

		if (!key || !obj || !locale) {
			return fallback;
		}

		const translation = obj[locale]?.[key];

		return translation || fallback;
	};

	return translate;
};

export const toast = (value, opts) => {
	if (!value.type) { value.type = 'success'; }
	let alert = {};

	if (value && value.status === 401) {
		alert = {
			text: 'Session error',
		};
	} else if (value instanceof Error) {
		alert = {
			type: 'error',
			text: value.message,
		};
	} else if (value.data && value.data.data) {
		alert = {
			text: value.data.data,
		};
	} else if (value.status) {
		alert = {
			text: value.data && value.data.message ? value.data.message : value.statusText,
		};
	} else if (typeof value === 'object') {
		alert = {
			...value,
		};
	} else {
		alert = {
			text: value.message || value,
		};
	}

	if (value && value.status && value.status >= 400) {
		alert.type = 'error';
	}

	const alertFinal = {
		text: tr(alert.text),
		type: alert.type || value.type,
	};

	const isError = alert.type === 'error';
	const isWarning = alert.type === 'warning';

	const options = {
		className: css({
			background: isError
				? '#eb5757 !important'
				: isWarning
					? '#e38512 !important'
					: '#00C78B !important', // can't use useStyles() here
			borderRadius: '3px !important',
		}),
		closeButton: false,
		type: isError
			? showToast.TYPE.ERROR
			: isWarning
				? showToast.TYPE.WARNING
				: showToast.TYPE.SUCCESS,
		hideProgressBar: true,
		position: 'bottom-right',
		autoClose: (isError || isWarning) ? 6000 : 3000,
		...opts,
	};

	showToast(<Toast text={alertFinal.text} type={alertFinal.type} />, options);
};

export const parseQuery = query => qs.parse(query);

export function usePageFilter(state = {}) {
	const history = useHistory();
	const { locale } = useSelector(state => state.locale);
	// const dispatch = useDispatch();
	const params = qs.parse(history.location.search);
	const [filter, setPageFilter] = useState({ ...state, ...params });
	const [initial, setInitial] = useState(state);

	const dispatchFilterUpdate = () => {
		// dispatch(updateFilter(history.location.pathname, history.location.search));
	};

	const debouncedUpdate = useCallback(debounce((filter) => {
		history.replace({
			search: qs.stringify(filter),
		});

		dispatchFilterUpdate();
	}, 400), []);

	useEffect(() => {
		setPageFilter({
			...filter,
			language: locale,
		});
	}, [locale]);

	const setFilter = (params) => {
		const page = params.page && filter.page === params.page ? { page: 0 } : {};

		const state = { ...filter, ...params, ...page };

		setPageFilter(state);

		debouncedUpdate(state);
	};

	const handleChangeFilter = (e) => {
		setFilter({
			...filter,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeOptions = (name, value) => {
		setFilter({
			...filter,
			[name]: value,
		});
	};

	const handleChangePage = (e, page) => {
		setFilter({
			...filter,
			page,
		});
	};

	const handleChangePageSize = (e) => {
		setFilter({
			...filter,
			page: 0,
			pageSize: e.target.value,
		});
	};

	const resetFilter = () => {
		setPageFilter(initial);

		history.replace({
			search: '',
			language: filter.language,
		});

		// dispatchFilterUpdate();
	};

	const setFilterStateInitial = (state = {}) => {
		setInitial({
			...initial,
			...state,
		});
	};

	const isFilterStateInitial = () => {
		return JSON.stringify(initial) === JSON.stringify(filter);
	};

	useEffect(() => {
		// dispatchFilterUpdate();
	}, [history.location.pathname]);

	return {
		filter,
		setFilter,
		handleChangeFilter,
		handleChangeOptions,
		handleChangePage,
		handleChangePageSize,
		resetFilter,
		isFilterStateInitial,
		setFilterStateInitial,
	};
}

export function getChipsByIds(options, values) {
	return options.filter((option) => { return values.indexOf(option.id) !== -1; });
}

export function mapOptions(option) {
	return {
		...option,
		label: option.title,
		value: option.id,
	};
}

export function mapCountries(country) {
	return {
		...country,
		label: country.name,
		value: country.id,
	};
}

export function mapUsers(user) {
	return {
		...user,
		title: `${user.firstName} ${user.lastName}`,
		label: `${user.firstName} ${user.lastName}`,
		value: user.id,
	};
}

export function useOptions(path) {
	const [options, setOptions] = useState([]);

	useEffect(() => {
		async function fetchOptions() {
			const params = { page: 0, pageSize: 1500 };

			await http.get(`${path}?${qs.stringify(params)}`).then((res) => {
				switch (path) {
				case '/api/admin/countries':
					setOptions(res.data.map(mapCountries));
					break;
				case '/api/kiosks/coworking/users':
					setOptions(res.data.data.map(mapUsers));
					break;
				default:
					setOptions(res.data.data.map(mapOptions));
				}
			}).catch(toast);
		}

		fetchOptions();
	}, []);

	return {
		options,
		setOptions,
	};
}

export function useStatus() {
	const [status] = useState([
		{ label: tr('Enabled'), value: 'enabled' },
		{ label: tr('Disabled'), value: 'disabled' },
		{ label: tr('All'), value: 'all' },
	]);

	return {
		status,
	};
}

export function useReservationStatus() {
	const [status] = useState([
		{ label: tr('Ended'), value: 'ended' },
		{ label: tr('Canceled'), value: 'disabled' },
		{ label: tr('Active'), value: 'active' },
		{ label: tr('All'), value: 'all' },
	]);

	return {
		status,
	};
}

export function useIssueStatus(filter) {
	const initial = [
		{ label: tr('New'), value: 'new' },
		{ label: tr('In progress'), value: 'in_progress' },
		{ label: tr('Closed'), value: 'closed' },
	];

	const [status] = useState(filter ? [
		...initial,
		{ label: tr('All'), value: 'all' },
	] : initial);

	return {
		status,
	};
}

export function useLinked() {
	const [linked] = useState([
		{ label: tr('Linked'), value: 'linked' },
		{ label: tr('Unlinked'), value: 'unlinked' },
		{ label: tr('All'), value: 'all' },
	]);

	return {
		linked,
	};
}

export function useDates() {
	const [dates] = useState([
		{ value: 'MMM DD, YYYY' },
		{ value: 'YYYY-MM-DD' },
		{ value: 'MM/DD/YYYY' },
		{ value: 'DD/MM/YYYY' },
	]);

	return {
		dates,
	};
}

export function useTimes() {
	const [times] = useState([
		{ value: 'HH:mm' },
		{ value: 'hh:mm a' },
		{ value: 'hh:mm A' },
	]);

	return {
		times,
	};
}

export function useRecords(path, filter, triggers = [], preventFetch = () => { }) {
	const [records, setRecords] = useState([]);
	const recordsFetch = {
		[path]: {
			controller: null,
			signal: null,
			inProgress: false,
		},
	};

	async function fetchRecords(params, signal) {
		await http.get(`${path}?${qs.stringify(params)}`, { signal }).then(res => {
			setRecords(res.data);
		}).catch(e => {
			if (e.name === 'AbortError') {
				return;
			}
			toast(e);
		});
	}

	const instantFetch = (params) => {
		if (recordsFetch[path].signal) {
			recordsFetch[path].controller.abort();
		}

		recordsFetch[path].controller = new AbortController();

		recordsFetch[path].signal = recordsFetch[path].controller.signal;
		fetchRecords(params, recordsFetch[path].signal);
	};

	const debouncedFetch = useCallback(debounce((params) => {
		instantFetch(params);
	}, 350), []);

	useEffect(() => {
		if (preventFetch(filter)) {
			return;
		}

		recordsFetch[path].inProgress = true;

		instantFetch(filter);
	}, triggers);

	useEffect(() => {
		if (preventFetch(filter)) {
			return;
		}

		if (recordsFetch[path].inProgress) {
			recordsFetch[path].inProgress = false;
			return;
		}

		debouncedFetch(filter);
	}, [filter]);

	return {
		records,
		setRecords,
		fetchRecords,
	};
}

export function useDetails(path, initial, triggers = []) {
	const [details, setDetails] = useState(initial);
	const [isLoading, setIsLoading] = useState(true);

	async function fetchDetails() {
		await http.get(path).then((res) => {
			setDetails(res.data);
			setIsLoading(false);
		}).catch(toast);
	}

	useEffect(() => {
		if (triggers.some(trigger => !trigger)) {
			setIsLoading(false);
			return;
		}

		setIsLoading(true);
		fetchDetails();
	}, triggers);


	return {
		details,
		setDetails,
		fetchDetails,
		isLoading,
	};
}

export function getListPagePath(pathname) {
	const filter = useSelector(store => store.filter);

	return filter[pathname] ? pathname + filter[pathname] : pathname;
}

export function getBackPath(pathname) {
	const lastLocation = useLastLocation();

	return lastLocation && lastLocation.pathname ? lastLocation.pathname + lastLocation.search : pathname;
}

export function getBackAction(pathname) {
	const history = useHistory();
	const path = getBackPath(pathname);

	return () => {
		history.push(path);
	};
}

export function isPreviousMatch(props) {
	const lastLocation = useLastLocation();

	return lastLocation && matchPath(lastLocation.pathname, props);
}

export function useSessionState(key, defaultValue) {
	const [state, setState] = useState(JSON.parse(sessionStorage.getItem(key)) || defaultValue);

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);

	return {
		state,
		setState,
	};
}
