export default function bookingReducer(state = {}, action) {
	// console.error('action');
	// console.log(action);

	switch (action.type) {
	case 'BOOKING_UPDATED':
		return {
			...state,
			id: action.id,
			// ...{ [action.data.pathname]: action.data.search },
		};
	default:
		return state;
	}
}
