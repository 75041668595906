const Yup = require('yup');

const checkoutSchema = (tr) => {
	return Yup.object().shape({
		name: Yup.string()
			.when('$patch', { is: true, then: i => i.notRequired(), otherwise: i => i.required(tr('Required')) }),
		surname: Yup.string()
			.when('$patch', { is: true, then: i => i.notRequired(), otherwise: i => i.required(tr('Required')) }),
		email: Yup.string()
			.email(tr('Must be an email'))
			.when('$patch', { is: true, then: i => i.notRequired(), otherwise: i => i.required(tr('Required')) }),
		phone: Yup.string()
			.when('$patch', { is: true, then: i => i.notRequired(), otherwise: i => i.required(tr('Required')) }),
		company: Yup.boolean(),
		companyName: Yup.string()
			.when('company', {
				is: true,
				then: Yup.string().required(tr('Required')),
			}),
		companyCode: Yup.string()
			.when('company', {
				is: true,
				then: Yup.string().required(tr('Required')),
			}),
		acceptsTermsConditions: Yup.boolean().oneOf([true], tr('Required')),
	});
};

module.exports = checkoutSchema;
