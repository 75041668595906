import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	toastBody: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	icon: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	text: {
		flex: 2,
	},
}));

const Toast = ({ text, type }) => {
	const classes = useStyles();
	const Icon = type === 'error' ? ErrorIcon : CheckCircle;

	return (
		<div className={classes.toastBody}>
			<Icon className={classes.icon} />
			<div className={classes.text}>{text}</div>
		</div>
	);
};

Toast.propTypes = {
	text: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};

export default Toast;
