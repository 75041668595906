export default function localeReducer(state = {}, action) {
	switch (action.type) {
	case 'LOCALE_CHANGED':
		return {
			...state,
			...action.payload,
		};
	default:
		return {
			...state,
			locale: 'en',
		};
	}
}
