export default function filterReducer(state = {}, action) {
	switch (action.type) {
	case 'FILTER_UPDATED':
		return {
			...state,
			...{ [action.data.pathname]: action.data.search },
		};
	default:
		return state;
	}
}
