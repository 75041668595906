import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	item: {
		height: 70,
		justifyContent: 'center',
		alignItems: 'center',
		paddingRight: 0,
		paddingLeft: 0,
		color: theme.palette.common.white,
		fontSize: 42,
	},
	active: {
		backgroundColor: '#3d2dff !important',
	},
}));

function NavigationItem({ active, children, section, sections = [] }) {
	const classes = useStyles();

	let isActive = false;

	if (sections.length && sections.indexOf(active) !== -1) {
		isActive = true;
	}

	if (section && active === section) {
		isActive = true;
	}

	return (
		<ListItem
			className={clsx(classes.item, {
				[classes.active]: isActive,
			})}
			button
			selected={isActive}
			component={Link}
			to={`/${section}`}>
			{children}
		</ListItem>
	);
}

NavigationItem.propTypes = {
	active: PropTypes.string.isRequired,
	children: PropTypes.node,
	section: PropTypes.string,
	sections: PropTypes.array,
};

export default NavigationItem;
