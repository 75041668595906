import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import { Button } from '@common/components';

import { useTranslation } from '@common/utils';
import { Link } from 'react-router-dom';

function Banner() {
	const classes = useStyles();
	const tr = useTranslation();
	const { locale } = useSelector(state => state.locale);

	return (
		<div className={classes.banner}>
			<Typography variant="h3">
				{tr('Need more products?')}
			</Typography>
			<div className={classes.action}>
				<Button color="dark" style={{ minWidth: '240px' }} component={Link} to={`/?language=${locale}`}>{tr('Add product')}</Button>
			</div>
			<Typography variant="body2" className={classes.note}>
				{tr('We will hold your reservation until you add all the products you need.')}
			</Typography>
		</div>
	);
}

export default Banner;

const useStyles = makeStyles(theme => ({
	banner: {
		border: `1px solid ${theme.palette.custom.borders}`,
		borderRadius: '4px',
		marginBottom: theme.spacing(5),
		padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
		textAlign: 'center',

		[theme.breakpoints.up('md')]: {
			padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
		},
	},
	action: {
		padding: theme.spacing(3),
	},
	note: {
		color: theme.palette.primary.light,
	},
}));
