import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { TextField, Typography , Popper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import moment from 'moment';

const getSlots = (offset) => Array.from(new Array(24 * 4)).map((_, i) => {
	const index = i + (offset ? 1 : 0);
	return {
		value: index * 15 * 60,
		label: `${index < 40 ? '0' : ''}${Math.floor(index / 4)}:${index % 4 === 0 ? '00' : index % 4 === 3 ? '45' : index % 4 === 2 ? '30' : '15'}`,
	};
});

const getDateInSeconds = (date, offset) => {
	return (60 * moment(date).hours() + Math.ceil(moment(date).minute() / 15) * 15) * 60 - (offset ? 1 : 0);
};

const getPickerValue = (slots, date, secondsFrom) => {
	if (!date) {
		return null;
	}

	let seconds = getDateInSeconds(date);

	if (secondsFrom > seconds) {
		seconds = secondsFrom;
	}

	return slots.find(slot => slot.value === seconds) || null;
};

function TimePicker({ prop, dates, setDates, period, handleTimeChange, unavailableRecords}) {
	const classes = useStyles();
	const slots = getSlots(prop === 'to');

	const isToday = dates[prop] && moment(dates[prop]).isSame(new Date(), 'day');
	const isDisabled = !dates.from;

	const secondsFrom = prop === 'from' && isToday ? getDateInSeconds(moment()) : prop === 'to' && dates.from ? getDateInSeconds(dates.from) + 15 * 60 : 0;

	const isOptionsDisabled = (options) => {
		return unavailableRecords.includes(options.value);
	};

	const pickerValue = getPickerValue(slots, dates[prop], secondsFrom);

	useEffect(() => {
		if (pickerValue?.value) {
			setDates({
				...dates,
				from: moment(dates.from).startOf('day').seconds(pickerValue.value).toISOString(),
				to: moment(dates.from).startOf('day').seconds(pickerValue.value + (period.durationPeriodQuantity * (period?.quantity || 1)) * 3600).subtract(1, 'ms').toISOString(),
			});
		} else {
			setDates({
				...dates,
				from: moment(dates.from).startOf('day').seconds(1).toISOString(),
				to: moment(dates.from).startOf('day').seconds(1 + (period.durationPeriodQuantity * (period?.quantity || 1)) * 3600).subtract(1, 'ms').toISOString(),
			});
		}
	}, [dates.from]);

	const PickerPopper = function (props) {
		return (<Popper {...props} style={{ width: 250 }}/>);
	};

	return (
		<Autocomplete
			options={slots.filter(slot => slot.value >= secondsFrom)}
			className={`${classes.picker} ${isDisabled ? classes.disabled : ''}`}
			classes={{ paper: classes.paper }}
			getOptionLabel={option => option.label}
			getOptionDisabled={option => unavailableRecords.includes(option.value)}
			disableClearable={true}
			disabled={isDisabled}
			value={getPickerValue(slots, dates[prop], secondsFrom)}
			openOnFocus={true}
			PopperComponent={PickerPopper}
			renderOption={(props) => {
				return (
					<li {...props} style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid lightGrey', backgroundColor: isOptionsDisabled(props) ? '#ededed' : 'none', margin: -6, padding: 6 }}>
						<Typography className={classes.option} >{props.label}</Typography>
					</li>
				);
			}}
			renderInput={(params) => {
				const { inputProps, InputProps } = params;

				return (
					<TextField {...params}
						inputProps={{ ...inputProps, className: `${inputProps.className} ${classes.input}` }}
						InputProps={{ ...InputProps }}
					/>
				);
			}}
			onChange={(e, option) => handleTimeChange({ prop, seconds: option.value })}
		/>
	);
}

TimePicker.propTypes = {
	prop: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.string,
	dates: PropTypes.object,
	handleTimeChange: PropTypes.func.isRequired,
	setDates: PropTypes.func.isRequired,
	period: PropTypes.object,
	unavailableRecords: PropTypes.array,
};

export default TimePicker;

const useStyles = makeStyles(() => ({
	picker: {
		width: '104px',
		borderRadius: '4px',
		userSelect: 'none',
	},
	disabled: {
		pointerEvents: 'none',
		opacity: 0.85,
	},
	input: {
		fontSize: '16px',
		padding: '4px !important',
		textAlign: 'center',
	},
	option: {
		fontSize: '16px',
		textAlign: 'center',
	},
	paper: { '& ul': { minHeight: '500px' } },
}));
