import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from '@common/utils';

import { Link } from 'react-router-dom';

function HeaderMessage({ message, theme, to }) {
	const classes = useStyles();
	const tr = useTranslation();

	const classCSS = `${classes.container} ${classes[theme]}`;

	return (
		<>
			{!!to &&
				<Link to={to} className={`${classCSS} ${classes.link}`}>
					{tr(message)}
				</Link>
			}
			{!to &&
				<div className={classCSS}>
					{tr(message)}
				</div>
			}
		</>
	);
}
HeaderMessage.propTypes = {
	message: PropTypes.string.isRequired,
	theme: PropTypes.string,
	to: PropTypes.string,
};

export default HeaderMessage;

const useStyles = makeStyles(theme => ({
	container: {
		height: '48px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textDecoration: 'none',
		overflow: 'hidden',
		width: '100%',
		padding: '0 16px',
		textAlign: 'center',
		lineHeight: '18px',
		fontSize: '16px',

		[theme.breakpoints.down('sm')]: {
			height: '52px',
			fontSize: '15px',
		},
	},
	dark: {
		color: '#fff',
		backgroundColor: theme.palette.primary.dark,
	},
	action: {
		color: '#fff',
		backgroundColor: theme.palette.custom.main,
	},
	link: {
		'&:hover': {
			opacity: 0.9,
		},
	},
}));
