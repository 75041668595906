import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import { useTranslation, useI18n } from '@common/utils';
import { HeaderMessage } from '@common/components';
import { useSettings } from '@common/hooks';
import { routeCoworkingOrder } from '@components/Router/Router';

function HeaderMessages() {
	const match = useRouteMatch(routeCoworkingOrder);
	const { reservationDates, i18n: i18nSettings } = useSettings();
	const i18n = useI18n(i18nSettings);
	const tr = useTranslation();

	const bookingConfirmationHeaderText = i18n('bookingConfirmationHeaderText', i18nSettings.bookingConfirmationHeaderText);
	const bannerText = i18n('bannerText', i18nSettings.bannerText);

	const opening = reservationDates.openingDate && moment(reservationDates.from).isAfter(moment()) ? ` from ${reservationDates.from.format('MMMM Do')}` : '';
	const closing = reservationDates.closingDate && moment(reservationDates.to).isAfter(moment()) ? ` until ${reservationDates.to.format('MMMM Do')}` : '';
	const duration = reservationDates.minDuration > 1 ? `${tr('Minimum stay is')} ${reservationDates.minDuration} ${tr('nights')}.` : '';

	return (
		<>
			{!!bookingConfirmationHeaderText && match?.isExact && <HeaderMessage message={bookingConfirmationHeaderText} theme="dark" />}
			{!!bannerText && <HeaderMessage message={bannerText} theme="dark" />}
			{(opening || closing || duration) &&
				<HeaderMessage message={`${opening || closing ? `${tr('We are now accepting reservations')}${opening}${closing}.` : ''}${tr(duration)}`} theme="action" />
			}
		</>
	);
}

export default HeaderMessages;
