import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { InputBase } from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DatePicker } from '@material-ui/pickers';

import { Paper, Grid } from '@material-ui/core';

import moment from 'moment';
import 'moment/locale/lt';

import { toast } from '@common/utils';
import { useTranslation } from '@common/utils';
import { useSettings } from '@common/hooks';
import customLocales from '@common/customMomentLocales';
import http from '@common/http';

const customLocale = {
	en: moment.localeData('en-gb'),
	lt: customLocales.lt,
	pl: customLocales.pl,
};

function DateComponent(pickerProps, boxes) {
	const classes = useStyles();

	const { format, inputRef, onClick, onKeyDown, placeholder, value } = pickerProps;
	const props = { format, inputRef, onClick, onKeyDown, value };

	if (boxes) {
		return (
			<label className={classes.box}>
				<div className={classes.label}>{placeholder}</div>
				<InputBase  {...props} className={classes.date} placeholder={boxes ? '-' : placeholder} readOnly />
			</label>
		);
	}

	return (
		<InputBase {...props} className={classes.input} placeholder={placeholder} />
	);
}

const thisMoment = moment();
const startOfMonth = moment(thisMoment).startOf('month');
const endOfMonth = moment(thisMoment).endOf('month');

const getAvailabilityDates = async (from, to, productId) => {
	const FROM = moment(from).toISOString();
	const TO = moment(to).toISOString();

	const body = productId ? { from: FROM, to: TO, products: [productId] } : { from: FROM, to: TO };

	const { data } = await http.post('/api/kiosks/coworking/products/availability', body);

	return data;
};

function DatePickers({ dates, setDates, period, boxes, productId, productAssetType, header }) {
	const classes = useStyles();
	const anchorElRef = useRef();
	const tr = useTranslation();
	const { locale } = useSelector(state => state.locale);
	moment.locale(locale, customLocale[locale]);

	const { reservationDates } = useSettings();
	const [anchorEl, setAnchorEl] = useState();
	const [availabilityDates, setAvailabilityDates] = useState([]);
	const [availabilityRange, setAvailabilityRange] = useState(
		{
			from: startOfMonth,
			to: endOfMonth,
		},
	);

	useEffect(() => {
		setAnchorEl(anchorElRef);
	}, [anchorElRef]);

	useEffect(async () => {
		const FROM = availabilityRange.from;
		const TO = availabilityRange.to;

		try {
			const data = await getAvailabilityDates(FROM, TO, productId);

			setAvailabilityDates(data);

		} catch (e) {
			setAvailabilityDates([]);

			toast(e);
		}

	}, [availabilityRange]);

	let selected = false;

	const handleDateClose = () => {
		if (!dates.from) {
			selected = false;
		}
	};

	const handleDateOpen = (prop) => {

		if (dates.from !== null && prop === 'from') {
			setAvailabilityRange({
				from: moment(dates.from).startOf('month'),
				to: moment(dates.from).endOf('month'),
			});
		} else if (dates.to !== null && prop === 'to') {
			setAvailabilityRange({
				from: moment(dates.to).startOf('month'),
				to: moment(dates.to).endOf('month'),
			});
		} else {
			setAvailabilityRange({
				from: availabilityRange?.from || startOfMonth,
				to: availabilityRange?.to || endOfMonth,
			});
		}
	};

	const handleDateChange = (prop, datetime) => {
		if ((moment(reservationDates.from).isAfter(moment()) || prop === 'to') && !selected && !dates[prop]) {
			selected = true;
			return;
		}

		let from = prop === 'from' ? moment(datetime).startOf('day') : dates.from ? moment(dates.from).startOf('day') : null;
		let to = moment(from).endOf('day');

		if (period && productAssetType === 'coworking') {
			const duration = period.durationPeriodQuantity * period.quantity <= 1 ? 1 : period?.durationPeriodQuantity * period?.quantity;
			if (period.durationPeriod === 'days') {
				to = moment(from).add(duration - 1, period.durationPeriod).endOf('day');
			} else {
				to = moment(from).add(duration, period.durationPeriod).subtract(1, 'ms').endOf('day');
			}
		}

		if (period && period.durationPeriod !== 'hours' && productAssetType === 'conferenceRoom') {
			const duration = period.durationPeriodQuantity * period.quantity <= 1 ? 1 : period?.durationPeriodQuantity * period?.quantity;
			if (period.durationPeriod === 'days') {
				to = moment(from).add(duration - 1, period.durationPeriod).endOf('day');
			} else {
				to = moment(from).add(duration, period.durationPeriod).subtract(1, 'ms').endOf('day');
			}
		}

		if (period && period.durationPeriod === 'hours' && productAssetType === 'conferenceRoom') {
			to = moment(from).add(period.durationPeriodQuantity * period.quantity, period.durationPeriod);
		}

		setDates({
			...dates,
			from: from.toISOString(),
			to: to.toISOString(),
		});
	};

	const handleMonthChange = (date) => {
		const FROM = moment(date).startOf('month');
		const TO = moment(date).endOf('month');

		setAvailabilityRange({
			from: FROM,
			to: TO,
		});

	};

	const getDisabledDates = (prop) => {
		const disabledDays = (availabilityDates || [])
			.filter(d => prop === 'checkIn' ? !d.availableCheckIn : !d.availableCheckOut)
			.map(d => Number(moment(d.day).format('D')));

		return disabledDays || [];
	};

	const getBoolean = (day, checkedDay) => {
		return (
			new Date(day).getDate() === new Date(checkedDay).getDate() &&
			new Date(day).getMonth() === new Date(checkedDay).getMonth() &&
			new Date(day).getFullYear() === new Date(checkedDay).getFullYear()
		);
	};

	function getDayElement(day, selectedDate, isInCurrentMonth, prop) {
		const today = new Date();
		const disabledDays = getDisabledDates(prop);

		const isDisabled = disabledDays.includes(new Date(day).getDate());
		const isSelected = getBoolean(day, selectedDate);
		const isToday = getBoolean(day, today);
		const isPastDays = new Date(day).getDate() < new Date(today).getDate() && new Date(day).getMonth() === new Date(today).getMonth() && new Date(day).getFullYear() === new Date(today).getFullYear();

		let specificDays;
		if (isInCurrentMonth) {
			if (isDisabled) {
				specificDays = (
					<div className={isSelected ? classes.specificSelectedDayPaper : {}} >
						<Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.specificDaysTodayPaper : classes.specificDaysNormalDayPaper}>
							<Grid item style={isToday && !isSelected ? { color: '#006CFF' } : {}}>
								<div className={!isSelected ? classes.daysHover : {}}>
									{new Date(day).getDate()}
								</div>
							</Grid>
						</Paper >
					</div>

				);
			} else {
				specificDays = (
					<Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper : classes.normalDayPaper}>
						<div className={!isSelected ? classes.daysHover : {}}>
							<Grid item > {new Date(day).getDate()}</Grid>
						</div>
					</Paper >
				);
			}
			if (isPastDays) {
				specificDays = (
					<Paper className={classes.pastDays}>
						<Grid item > {new Date(day).getDate()}</Grid>
					</Paper>
				);
			}
		} else {
			specificDays = (<Paper className={classes.notInThisMonthDayPaper}>
				<Grid item style={{ display: 'none' }}>
					{new Date(day).getDate()}
				</Grid>
			</Paper>
			);
		}
		return specificDays;
	}

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
			<div ref={anchorElRef} className={`${classes.container} ${boxes ? classes.boxes : header ? classes.header : classes.pickers}`}>
				<DatePicker
					disableToolbar
					variant="inline"
					placeholder={tr('Select Date')}
					format={boxes ? 'MMM-DD' : 'YYYY-MM-DD'}
					value={dates.from}
					autoOk={true}
					minDate={moment(reservationDates.from)}
					maxDate={moment(reservationDates.to).subtract(reservationDates.minDuration, 'days')}
					TextFieldComponent={(props) => DateComponent(props, boxes)}
					onChange={(date) => handleDateChange('from', date)}
					onClose={handleDateClose}
					onOpen={() => handleDateOpen('from')}
					renderDay={(day, selectedDate, isInCurrentMonth) => getDayElement(day, dates.from, isInCurrentMonth, 'checkIn')}
					onMonthChange={(date) => handleMonthChange(date)}
					PopoverProps={{
						anchorEl: () => anchorEl ? anchorEl.current : '',
					}}
				/>
			</div>
		</MuiPickersUtilsProvider>
	);
}

DatePickers.propTypes = {
	dates: PropTypes.object.isRequired,
	period: PropTypes.object,
	setDates: PropTypes.func.isRequired,
	boxes: PropTypes.bool,
	header: PropTypes.bool,
	productId: PropTypes.string,
	productAssetType: PropTypes.string,
};

export default DatePickers;

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	pickers: {
		padding: '3px 8px',
		border: '1px solid #313133',
		borderRadius: '4px',

		[theme.breakpoints.down('sm')]: {
			padding: '2px 0',
			marginBottom: theme.spacing(2),
		},
	},
	header: {
		padding: '3px 8px',
		border: `1px solid ${theme.palette.custom.borders}`,
		borderRadius: '4px',

		[theme.breakpoints.down('sm')]: {
			padding: '2px 0',
			marginBottom: theme.spacing(2),
		},
	},
	input: {
		border: 0,
		width: '128px',
		padding: '4px 0',
		fontFamily: 'inherit',
		color: theme.palette.primary.dark,
		'& input': {
			textAlign: 'center',
			fontSize: '16px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '110px',
		},
	},
	separator: {
		width: '14px',
		height: '1px',
		backgroundColor: theme.palette.custom.separator,

		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},

	label: {
		fontWeight: 600,
	},
	box: {
		textAlign: 'center',
		padding: '1px 16px',
		border: `1px solid ${theme.palette.custom.borders}`,
		borderRadius: '4px',
		lineHeight: '1.4rem',
		marginLeft: '8px',
		cursor: 'pointer',

		[theme.breakpoints.up('sm')]: {
			padding: '1px 32px',
		},
		[theme.breakpoints.up('md')]: {
			padding: '1px 48px',
		},

		'&:hover': {
			borderColor: theme.palette.custom.main,
		},
	},
	date: {
		color: theme.palette.primary.dark,
		width: '70px',
		height: '22px',
		'& input': {
			textAlign: 'center',
			fontSize: '16px',
			cursor: 'pointer',
		},
	},
	notInThisMonthDayPaper: {
		width: '39.5px',
		height: '39.5px',
		boxShadow: 'none',
		padding: '1px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	pastDays: {
		width: '39.5px',
		height: '39.5px',
		boxShadow: 'none',
		padding: '1px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#aeaeae',
	},
	normalDayPaper: {
		width: '39.5px',
		height: '39.5px',
		boxShadow: 'none',
		borderRadius: 0,
		padding: '1px',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	selectedDayPaper: {
		width: '39.5px',
		height: '39.5px',
		backgroundColor: '#006CFF',
		color: 'white',
		boxShadow: 'none',
		borderRadius: '50%',
		border: '2px solid #fff',
		padding: '1px',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	todayPaper: {
		width: '39.5px',
		height: '39.5px',
		boxShadow: 'none',
		padding: '1px',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
	},
	specificDaysTodayPaper: {
		width: '39.5px',
		height: '39.5px',
		color: '#006CFF',
		boxShadow: 'none',
		padding: '1px',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 0,
		backgroundColor: '#f2f2f2',
	},
	specificDaysNormalDayPaper: {
		width: '39.5px',
		height: '39.5px',
		boxShadow: 'none',
		borderRadius: 0,
		padding: '1px',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#f2f2f2',
	},
	daysHover: {
		width: '36px',
		height: '36px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&:hover': {
			borderRadius: '50%',
			border: '2px solid #006CFF',
		},
	},
	specificSelectedDayPaper: {
		width: '39.5px',
		height: '39.5px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#f2f2f2',
	},
}));
