import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';

import ServiceCard from './ServiceCard';

function Services({ services }) {
	const classes = useStyles();

	return (
		<div className={classes.services}>
			<Grid container spacing={3}>
				{services.map((service, i) => {
					return (
						<Grid key={`service-${i}`} item xs={12} md={4}>
							<ServiceCard service={service} />
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
}

Services.propTypes = {
	services: PropTypes.array,
};

export default Services;

const useStyles = makeStyles(theme => ({
	services: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(5),
	},
}));
