import React from 'react';

import { SvgIcon } from '@material-ui/core';

function IconLeft(props) {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M14.998,6c0.272,0,0.544,0.11,0.742,0.327 c0.371,0.409,0.341,1.042-0.068,1.413L10.977,12l4.695,4.26c0.409,0.371,0.439,1.004,0.068,1.413 c-0.373,0.408-1.008,0.438-1.416,0.067L8,12l6.324-5.74C14.516,6.086,14.758,6,14.998,6"
			/>
		</SvgIcon>
	);
}

export default IconLeft;
