import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import * as qs from 'query-string';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Card, Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import moment from 'moment';

import { useTranslation, useDetails } from '@common/utils';
import { useSettings } from '@common/hooks';
import { Link } from 'react-router-dom';

import { ClearIcon } from '@common/components/Icon';

function BookingCard({ booking, removeReservations }) {
	const classes = useStyles();
	const tr = useTranslation();
	const { locale } = useSelector(state => state.locale);
	const { currencySymbol } = useSettings();

	const { details: product } = useDetails(`/api/kiosks/coworking/products/${booking.product}`, {
		from: '',
		price: 0,
		product: '',
		productAssetPersonCapacity: 0,
		productAssetType: '',
		productPersonCapacity: 0,
		quantity: 0,
		reservation: [],
		to: '',
		total: 0,
		totalServices: 0,
		i18n: {},
	}, [booking.product]);

	const isDorm = product.assetType === 'dorm';

	const getDaysCount = (from, to) => {
		if (!from || !to) {
			return;
		}

		const start = moment(from).subtract(1, 'day');
		const end = moment(to);

		let numOfDays = 0;

		while (start.format('YYYYMMDD') < end.format('YYYYMMDD')) {
			numOfDays++;
			start.add(1, 'day');
		}

		return numOfDays;
	};

	const getHoursCount = (from, to) => {
		if (!from || !to) {
			return;
		}

		const duration = moment.duration(moment(to).add(1, 's').diff(from));
		const hours = duration.hours();

		return hours;
	};

	return (
		<Card className={classes.card}>
			<div className={classes.product}>
				<Link to={`/${booking.product}?${qs.stringify({ from: booking.from, to: booking.to })}`} className={classes.link}>
					<div className={classes.image} style={{ backgroundImage: product.image ? `url(/images/${product.image})` : 'none' }} />
				</Link>
			</div>
			<div className={classes.info}>
				<div>
					<Typography variant="h4" className={classes.headline}>
						{product?.i18n[locale]?.title || product.title}
					</Typography>
				</div>
				{booking.from && booking.to && <div className={classes.dates}>
					<div>
						<b>{tr('Check-in')}</b>
						&nbsp;
						{moment(booking.from).format(booking.durationPeriod === 'hours' ? 'YYYY-MM-DD HH:mm' : 'MMM DD, YYYY')}
					</div>
					<div>
						<b>{tr('Check-out')}</b>
						&nbsp;
						{moment(booking.to).add(booking.durationPeriod === 'hours' ? 1 : 0, 'ms').format(booking.durationPeriod === 'hours' ? 'YYYY-MM-DD HH:mm' : 'MMM DD, YYYY')}
					</div>
				</div>}
			</div>


			<Table className={classes.table}>
				<TableBody>
					<TableRow>
						<TableCell className={`${classes.col} ${classes.section}`} colSpan="3">
							{tr((booking.from && booking.to) ? isDorm ? 'Beds' : 'Rooms' : 'Items')}
						</TableCell>
					</TableRow>
					<TableRow>
						{booking.from && booking.to && <TableCell className={`${classes.col} ${classes.first}`}>1 {tr(isDorm ? 'bed' : 'room')}</TableCell>}
						{(!booking.from || !booking.to) && <TableCell className={`${classes.col} ${classes.first}`}>1 {tr('item')}</TableCell>}
						<TableCell className={classes.col} align="right"></TableCell>
						<TableCell className={`${classes.col} ${classes.last}`} align="right">{booking.price} {currencySymbol}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={`${classes.col} ${classes.colQTY} ${classes.first}`}>{tr('Quantity')}</TableCell>
						<TableCell className={`${classes.col} ${classes.colQTY}`} align="right"></TableCell>
						<TableCell className={`${classes.col} ${classes.colQTY} ${classes.last}`} align="right">{booking.quantity}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={`${classes.col} ${classes.colQTY} ${classes.first}`}>{tr(booking.durationPeriod === 'hours' ? 'Hours' : 'Days')}</TableCell>
						<TableCell className={`${classes.col} ${classes.colQTY}`} align="right"></TableCell>
						<TableCell className={`${classes.col} ${classes.colQTY} ${classes.last}`} align="right">{booking.durationPeriod === 'hours' ? getHoursCount(booking.from, booking.to) : getDaysCount(booking.from, booking.to)}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell className={`${classes.col} ${classes.sep}`} colSpan="3">
							<div className={classes.separator} />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={`${classes.col} ${classes.section}`} colSpan="3">
							{tr('Services')}
						</TableCell>
					</TableRow>
					<TableRow className={classes.row}>
						<TableCell className={`${classes.col} ${classes.first}`}>-</TableCell>
						<TableCell className={classes.col} align="right"></TableCell>
						<TableCell className={`${classes.col} ${classes.last}`} align="right">{booking.totalServices} {currencySymbol}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell className={`${classes.col} ${classes.sep}`} colSpan="3">
							<div className={classes.separator} />
						</TableCell>
					</TableRow>
					<TableRow className={classes.row}>
						<TableCell className={`${classes.col} ${classes.first}`}><b>{tr('Price (taxes incl.)')}</b></TableCell>
						<TableCell className={classes.col}></TableCell>
						<TableCell className={`${classes.col} ${classes.last}`} align="right"><b>{booking.total} {currencySymbol}</b></TableCell>
					</TableRow>
				</TableBody>
			</Table>
			{removeReservations && !booking.order.length && !booking.fee &&
				<div className={classes.removeBox}>
					<Button className={classes.removeButton} onClick={() => removeReservations(booking.reservation)}>
						<ClearIcon className={classes.icon} />
						{tr('remove reservation')}
					</Button>
				</div>
			}
		</Card>
	);
}

BookingCard.propTypes = {
	booking: PropTypes.object.isRequired,
	removeReservations: PropTypes.func,
};

export default BookingCard;

const useStyles = makeStyles(theme => ({
	card: {
		borderRadius: '4px',
		border: `1px solid ${theme.palette.custom.borders}`,
		boxShadow: 'none',
		padding: theme.spacing(3),
	},
	headline: {
		marginBottom: theme.spacing(2),
	},
	product: {
		marginBottom: theme.spacing(3),
	},
	link: {
		flex: '0 0 calc(50% + 12px)',
		height: '180px',
	},
	image: {
		width: '118%',
		height: '180px',
		margin: '-24px 24px 0 -24px',
		backgroundColor: theme.palette.custom.background,
		borderRadius: '4px 0 0',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',

		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	removeBox: {
		textAlign: 'center',
	},
	removeButton: {
		marginBottom: '-12px',
		color: theme.palette.primary.light,
		textTransform: 'lowercase',
	},
	icon: {
		fontSize: '20px',
		marginRight: '8px',
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingBottom: '24px',
		flex: 1,
	},
	section: {
		paddingLeft: 0,
		fontSize: '18px',
		color: theme.palette.primary.light,
		fontWeight: 600,
	},
	col: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		borderBottom: 0,
	},
	colQTY: {
		paddingTop: 0,
	},
	first: {
		paddingLeft: 0,
	},
	last: {
		paddingRight: 0,
	},
	sep: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	separator: {
		height: '1px',
		backgroundColor: theme.palette.custom.separator,
	},


	// header: {
	// 	display: 'flex',
	// 	flexDirection: 'row',
	// },
	// aside: {
	// 	flex: '0 0 auto',
	// },
	// content: {
	// 	padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
	// },
	// actions: {
	// 	padding: '0 24px 24px',
	// },
	// media: {
	// 	height: '248px',
	// 	backgroundColor: theme.palette.custom.background,
	// },
	// props: {
	// 	display: 'flex',
	// 	flexDirection: 'row',
	// 	marginBottom: theme.spacing(2),
	// },
	// prop: {
	// 	padding: `5px ${theme.spacing(2)}px`,
	// 	border: `1px solid ${theme.palette.custom.borders}`,
	// 	color: theme.palette.primary.light,
	// 	borderRadius: '18px',
	// 	whiteSpace: 'nowrap',
	// 	'&:nth-child(n+2)': {
	// 		marginLeft: theme.spacing(2),
	// 	}
	// },
	// price: {
	// 	lineHeight: '2.1rem',
	// },
	// currency: {
	// 	color: theme.palette.primary.light,
	// 	paddingLeft: '4px',
	// },
}));
