import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { Button as MaterialButton } from '@material-ui/core';

import { useTranslation } from '@common/utils';

function Button(params) {
	const classes = useStyles();
	const tr = useTranslation();

	const props = { ...params };

	delete props.color;
	delete props.wide;
	delete props.children;

	return (
		<MaterialButton disableRipple className={`${classes.button} ${params.wide ? classes.wide : ''} ${params.color ? classes[params.color] : ''}`} {...props}>
			{tr(params.children)}
		</MaterialButton>
	);
}

Button.propTypes = {
	color: PropTypes.string,
	wide: PropTypes.bool,
	children: PropTypes.node,
};

export default Button;

const useStyles = makeStyles(theme => ({
	button: {
		padding: `${theme.spacing(1.5)}px ${theme.spacing(6)}px`,
		fontSize: '16px',
		lineHeight: '22px',
		textTransform: 'initial',
		color: theme.palette.primary.dark,
		textDecoration: 'none',
		border: `1px solid ${theme.palette.primary.dark}`,
		borderRadius: '48px',
		'&:hover': {
			opacity: 0.9,
		},
	},
	wide: {
		borderRadius: '4px',
		width: '100%',
	},
	dark: {
		color: '#fff',
		backgroundColor: `${theme.palette.primary.dark} !important`,
		borderColor: theme.palette.primary.dark,

		'&[disabled]': {
			color: '#fff',
			opacity: 0.3,
		},
	},
	primary: {
		color: '#fff',
		backgroundColor: `${theme.palette.primary.main} !important`,
		borderColor: theme.palette.primary.main,

		'&[disabled]': {
			color: '#fff',
			opacity: 0.25,
		},
	},
}));
