import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Button, InputBase } from '@material-ui/core';

function QuantitySelector({ quantity, max = 1, handleChange }) {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Button className={classes.button} disabled={quantity === 1} onClick={() => handleChange(quantity-1)}>
				&#60;
			</Button>
			<InputBase
				type="number"
				className={classes.InputBase}
				value={quantity}
				inputProps={{ min: 1, max, className: classes.input }}
				onChange={(e) => handleChange(Math.min(max, parseInt(e.target.value)))}
			/>
			<Button className={classes.button} disabled={max ? quantity === max : false} onClick={() => handleChange(quantity+1)}>
				&#62;
			</Button>

		</div>
	);
}

QuantitySelector.propTypes = {
	quantity: PropTypes.number.isRequired,
	max: PropTypes.number,
	handleChange: PropTypes.func.isRequired,
};

export default QuantitySelector;

const useStyles = makeStyles(theme => ({
	container: {
		width: '84px',

	},
	InputBase: {
		width: '36px',
	},
	input: {
		textAlign: 'right',
		fontSize: '15px',
	},
	button: {
		width: '24px',
		height: '24px',
		background: theme.palette.custom.background,
		color: theme.palette.primary.light,
		padding: 0,
		minWidth: '24px',
		borderRadius: '2px',
	},
}));
