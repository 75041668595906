export default {
	lt: {
		months: 'Sausis_Vasaris_Kovas_Balandis_Gegužė_Birželis_Liepa_Rugpjūtis_Rugsėjis_Spalis_Lapkritis_Gruodis'.split('_'),
		monthsShort: 'Sau_Vas_Kov_Bal_Geg_Bir_Lie_Rugp_Rugs_Spa_Lap_Gru'.split('_'),
		weekdays: 'Sekmadienis_Pirmadienis_Antradienis_Trečiadienis_Ketvirtadienis_Penktadienis_Šeštadienis'.split('_'),
		weekdaysShort: 'Sek_Pir_Ant_Tre_Ket_Pen_Šeš'.split('_'),
		weekdaysMin: 'Se_Pi_An_Tr_Ke_Pe_Še'.split('_'),
		longDateFormat: {
			LT: 'HH:mm',
			LTS: 'HH:mm:ss',
			L: 'YYYY-MM-DD',
			LL: 'YYYY [m.] MMMM D [d.]',
			LLL: 'YYYY [m.] MMMM D [d.], HH:mm',
			LLLL: 'YYYY [m.] MMMM D [d.], dddd, HH:mm',
		},
		calendar: {
			sameDay: '[Šiandien] LT',
			nextDay: '[Rytoj] LT',
			nextWeek: 'dddd LT',
			lastDay: '[Vakar] LT',
			lastWeek: function () {
				switch (this.day()) {
				case 0:
				case 3:
				case 6:
					return '[Praeitą] dddd [at] LT';
				default:
					return '[Praeitį] dddd [at] LT';
				}
			},
			sameElse: 'L',
		},
		relativeTime: {
			future: 'po %s',
			past: 'prieš %s',
			s: 'kelių sekundžių',
			ss: '%d sekundžių',
			m: 'minutės',
			mm: '%d minučių',
			h: 'valanda',
			hh: '%d valandos',
			d: 'dienos',
			dd: '%d dienos',
			M: 'mėnesio',
			MM: '%d mėnesių',
			y: 'metų',
			yy: '%d metų',
		},
		ordinal: function (number) {
			return number;
		},
		week: {
			dow: 1,
			doy: 4,
		},
	},
	pl: {
		months: 'Styczeń_Luty_Marzec_Kwiecień_Maj_Czerwiec_Lipiec_Sierpień_Wrzesień_Październik_Listopad_Grudzień'.split('_'),
		monthsShort: 'Sty_Lut_Mar_Kwi_Maj_Cze_Lip_Sie_Wrz_Paź_Lis_Gru'.split('_'),
		weekdays: 'Niedziela_Poniedziałek_Wtorek_Środa_Czwartek_Piątek_Sobota'.split('_'),
		weekdaysShort: 'Nd_Pon_Wt_Śr_Czw_Pt_So'.split('_'),
		weekdaysMin: 'Nd_Pn_Wt_Śr_Cz_Pt_So'.split('_'),
		longDateFormat: {
			LT: 'HH:mm',
			LTS: 'HH:mm:ss',
			L: 'DD.MM.YYYY',
			LL: 'D MMMM YYYY',
			LLL: 'D MMMM YYYY HH:mm',
			LLLL: 'dddd, D MMMM YYYY HH:mm'
		},
		calendar: {
			sameDay: '[Dziś o] LT',
			nextDay: '[Jutro o] LT',
			nextWeek: '[W] dddd [o] LT',
			lastDay: '[Wczoraj o] LT',
			lastWeek: function () {
				switch (this.day()) {
				case 0:
					return '[W zeszłą niedzielę o] LT';
				case 3:
					return '[W zeszłą środę o] LT';
				case 6:
					return '[W zeszłą sobotę o] LT';
				default:
					return '[W zeszły] dddd [o] LT';
				}
			},
			sameElse: 'L',
		},
		relativeTime: {
			future: 'za %s',
			past: '%s temu',
			s: 'kilka sekund',
			ss: '%d sekund',
			m: 'minuta',
			mm: '%d minut',
			h: 'godzina',
			hh: '%d godzin',
			d: 'dzień',
			dd: '%d dni',
			M: 'miesiąc',
			MM: '%d miesięcy',
			y: 'rok',
			yy: '%d lat',
		},
		ordinalParse: /\d{1,2}\./,
		ordinal: '%d.',
		week: {
			dow: 1,
			doy: 4,
		},
	},
};