import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import http from '@common/http';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { AppBar, Button } from '@material-ui/core';
import { Container, LanguagePicker } from '@common/components';
import moment from 'moment';

import { useSessionState, useI18n } from '@common/utils';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { changeLocale } from '@redux/actions/localeActions';

import { useSettings } from '@common/hooks';

import { usePageFilter } from '@common/utils';

import { Link } from 'react-router-dom';

const AppLogo = () => {
	const classes = useStyles();
	const { locale } = useSelector(state => state.locale);

	return (
		<Link to={`/?language=${locale}`}>
			<div className={classes.logo}>
				<img src="/img/kiosks/coworking/logo.png" className={classes.img} alt="" />
			</div>
		</Link>
	);
};

const CountDown = ({ timer }) => {
	return (
		<div>{timer}</div>
	);
};

const Tagline = ({ content }) => {
	const classes = useStyles();

	return (
		<div className={classes.tagline}>
			{content}
		</div>
	);
};

function Header({ children }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { state: bookingId } = useSessionState('booking-id', '');
	const [booking, setBooking] = useState({});
	const timerRef = useRef();
	const [timer, setTimer] = useState('');
	const [bookingExpired, setBookingExpired] = useState(null);
	const { headerLinkTo, i18n: i18nSettings, languages } = useSettings();
	const i18n = useI18n(i18nSettings);
	const { locale } = useSelector(state => state.locale);
	const { filter, setFilter } = usePageFilter();

	const headerLinkText = i18n('headerLinkText', i18nSettings.headerLinkText);
	const headerText = i18n('headerText', i18nSettings.headerText);

	const { expiresAt, expired } = booking;

	useEffect(() => {
		if (!bookingId) {
			return;
		}

		http.get(`/api/kiosks/coworking/bookings/${bookingId}`).then(({ data } = {}) => setBooking(data));

	}, [bookingId]);

	const localExpiresAt = moment(expiresAt).toDate();

	useEffect(() => {
		let isMounted = true;

		if (!bookingId || !booking._id || expired || !expiresAt) {
			return;
		}

		timerRef.current = setInterval(() => {
			if (!isMounted) {
				return;
			}

			const now = new Date();
			const difference = moment(localExpiresAt).diff(now, 'seconds');

			if (difference <= 0) {
				setBookingExpired(true);
				clearInterval(timerRef.current);
				return;
			}
			if (bookingExpired !== false) {
				setBookingExpired(false);
			}

			const minutes = Math.floor(difference / 60);
			const seconds = difference % 60;

			setTimer(
				`${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
			);
		}, 1000);

		return () => {
			isMounted = false;
		};
	}, [expiresAt, expired, bookingId, booking.id]);

	const handleChangeLocale = e => {dispatch(changeLocale(e.target.value)); setFilter({ ...filter, language: e.target.value }); };

	return (
		<AppBar position="static" color="transparent" className={classes.header}>
			<Container>
				<div className={classes.inner}>
					<AppLogo />
					<Tagline content={headerText} />
					{!!headerLinkText && <Button
						className={`${classes.workationButton} ${classes.hiddenSm}`}
						variant='contained'
						target="_blank"
						href={headerLinkTo}
					>
						{headerLinkText}
					</Button>
					}
					<div className={classes.aside}>
						{languages && languages.length > 1 &&
							<LanguagePicker onChange={handleChangeLocale} languages={languages} />
						}
						{children}
					</div>
					{bookingId && bookingExpired === false &&
						<div className={classes.shoppingCartContainer}>
							<Button
								component={Link}
								className={classes.shoppingCart}
								to={`/booking/${bookingId}/?language=${locale}`}
							>
								<div className={classes.timerIcon}>
									<ShoppingCart/>
								</div>
								<div className={classes.timerText}>
									<CountDown timer={timer}/>
								</div>
							</Button>
						</div>
					}
				</div>
			</Container>
		</AppBar>
	);
}

Header.propTypes = {
	children: PropTypes.node,
};

Tagline.propTypes = {
	content: PropTypes.string,
};

CountDown.propTypes = {
	timer: PropTypes.string,
};

export default Header;

const useStyles = makeStyles(theme => ({
	header: {
		marginBottom: theme.spacing(5),
		boxShadow: 'none',

		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(1.5),
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: theme.spacing(2.5),
			paddingBottom: theme.spacing(2.5),
			borderBottom: `1px solid ${theme.palette.custom.separator}`,
		},

	},
	inner: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
	logo: {
		display: 'flex',
		justifyContent: 'center',
	},
	img: {
		maxWidth: '150px',
		maxHeight: '48px',
		height: 'auto',
	},
	timerIcon: {
		flex: 1,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	timerText: {
		flex: 1,
		alignItems: 'center',
		display: 'flex',
	},
	shoppingCartContainer: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
	},
	shoppingCart: {
		padding: '12.5px 12px 12px 24px',
		display: 'flex',
		width: 100,
		justifyContent: 'space-around',
		marginLeft: 8,
		alignItems: 'center',
	},
	tagline: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1.5),
		},
		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(3),
			padding: '12px 12px 12px 24px',
			borderLeft: `1px solid ${theme.palette.custom.separator}`,
		},
	},
	aside: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'row',
		marginLeft: 8,

		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.up('md')]: {
			justifyContent: 'flex-end',
		},
	},
	workationButton: {
		padding: '8px 32px',
		textTransform: 'initial',
		fontSize: '16px',
		boxShadow: 'none',

		'&:hover': {
			boxShadow: 'none',
		},
	},
	hiddenSm: {
		marginLeft: '16px',
		borderRadius: '32px',

		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	hiddenMd: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
	},
}));
