import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Grid, TextareaAutosize } from '@material-ui/core';

import { Container } from '@common/components';

import { useTranslation } from '@common/utils';

import { Link } from 'react-router-dom';
import visa from './img/visa_logo.png';
import masterCard from './img/master_card_logo.png';

import { useSettings } from '@common/hooks';

const PaymentLogo = () => {
	const classes = useStyles();

	return (
		<div className={classes.logo}>
			<img src={visa} className={classes.img} alt="" />
			<img src={masterCard} className={classes.img} alt="" />
		</div>
	);
};

const Links = ({ label, href }) => {
	const classes = useStyles();
	const tr = useTranslation();

	return (
		<Link style={{ textDecoration: 'none' }} to={href} target="_blank">
			<div className={classes.link}>
				{tr(label)}
			</div>
		</Link>
	);
};

const TextArea = ({ value }) => {
	const classes = useStyles();

	const textAreaElements = document.querySelectorAll(`.${classes.textArea}`);

	textAreaElements.forEach(element => {
		element.style.webkitAppearance = 'none';
		element.style.border = 'none';
		element.style.outline = 'none';
		element.style.fontSize = '15px';
	});

	return (
		<TextareaAutosize
			className={classes.textArea}
			value={value}
			margin="dense"
			spellCheck="false"
			style={{ outline: 'none' }}
		/>
	);
};

function Footer() {
	const classes = useStyles();

	const { locale } = useSelector(state => state.locale);

	const { credentials } = useSettings();

	return (
		<div className={classes.footer}>
			<Container>
				<div className={classes.inner}>
					<div>
						<Links label="Terms and Conditions" href={`/terms/?language=${locale}`} />
						<Links label="Privacy Policy" href={`/privacy/?language=${locale}`} />
					</div>
					<div className={classes.credentials}>
						<Grid item style={{ flex: 1 }}>
							{credentials &&
								<TextArea value={credentials} />
							}
						</Grid>
						<PaymentLogo />
					</div>
				</div>
			</Container>
		</div>
	);
}

Footer.propTypes = {
	children: PropTypes.node,
};

Links.propTypes = {
	label: PropTypes.string,
	href: PropTypes.string,
};

TextArea.propTypes = {
	value: PropTypes.string,
};

export default Footer;

const useStyles = makeStyles(theme => ({
	footer: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(3),
		boxShadow: 'none',
		width: '100%',
		height: '50px',
		bottom: 0,
		position: 'absolute',

		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(1.5),
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: theme.spacing(2.5),
			borderTop: `1px solid ${theme.palette.custom.separator}`,
		},

	},
	inner: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),

	},
	credentials: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minWidth: '30%',
		justifyContent: 'space-between',
	},
	logo: {
		display: 'flex',
		flexDirection: 'column',
	},
	img: {
		maxWidth: '70px',
		height: 'auto',
	},
	link: {
		color: '#949494',
		fontWeight: 'bold',
		marginBottom: '4px',

		'&:hover': {
			color: '#313133',
		},
	},
	textArea: {
		border: 'none',
		resize: 'none',
		width: '100%',
		fontWeight: 'bold',
		color: '#949494',
		backgroundColor: 'unset',
		marginBottom: theme.spacing(2),
	},
}));
