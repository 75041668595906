import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

import { App } from './components';

import * as serviceWorker from '@common/serviceWorker';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from '@common/theme';

import './index.css';

Sentry.init({
	dsn: 'https://75a318d4a52141ab8f0c62cfa3bcc68c@sentry.dev.gigsis.lt/17',
	attachStacktrace: true,
	beforeSend: function (event) {
		return process.env.NODE_ENV === 'production' ? event : null;
	},
	integrations: [
		new CaptureConsole({
			levels: ['error'],
		}),
	],
});

const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<App />
		</ThemeProvider>
	</Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
	module.hot.accept();
}
