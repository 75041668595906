export default function settingsReducer(state = {}, action) {
	switch (action.type) {
	case 'SETTINGS_UPDATED':
		return {
			...state,
			...action.data,
		};
	default:
		return state;
	}
}
