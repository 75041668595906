import { useSelector } from 'react-redux';

import moment from 'moment';

export function useSettings() {
	const settings = useSelector(state => state.settings);

	const reservationDates = {
		minDuration: settings.minimumDuration,
		openingDate: settings.openingDate,
		closingDate: settings.closingDate,
		from: moment(settings.openingDate).isAfter(moment()) ? moment(settings.openingDate) : moment(),
		to: moment(settings.closingDate).isAfter(moment()) ? moment(settings.closingDate) : moment('2100-01-01'),
	};

	return {
		reservationDates,
		googleMapsAPIKey: settings.googleMapsAPIKey || '',
		headerText: settings.headerText,
		headerLinkTo: settings.headerLinkTo,
		headerLinkText: settings.headerLinkText,
		bookingConfirmationHeaderText: settings.bookingConfirmationHeaderText,
		bookingConfirmationLeftText: settings.bookingConfirmationLeftText,
		bookingConfirmationRightText: settings.bookingConfirmationRightText,
		bookingFailureLeftText: settings.bookingFailureLeftText,
		bookingFailureRightText: settings.bookingFailureRightText,
		privacyPolicy: settings.privacyPolicy || '',
		termsAndConditions: settings.termsAndConditions || '',
		i18n: settings.i18n || {},
		paymentGatewaySettings: settings.paymentGatewaySettings,
		currencySymbol: settings.currencySymbol,
		currencyCode: settings.currencyCode,
		credentials: settings.credentials,
		languages: settings.languages,
		defaultLanguage: settings.defaultLanguage,
	};
}
