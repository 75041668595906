import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';

import { useTranslation } from '@common/utils';
import { Link } from 'react-router-dom';

function Progress({ status, id }) {
	const classes = useStyles();
	const tr = useTranslation();
	const { locale } = useSelector(state => state.locale);

	const trackProceedToCheckout = () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ 'event': 'Proceed to Checkout', 'source': 'breadcrumb' });
	};

	return (
		<div className={classes.progress}>
			{status === 'confirmation' &&
				<div className={`${classes.step} ${classes.outline}`}>
					{tr('Booking confirmation')}
				</div>
			}
			{status === 'cancellation' &&
				<div className={`${classes.step} ${classes.error}`}>
					{tr('Payment failed')}
				</div>
			}
			{status !== 'confirmation' && status !== 'cancellation' &&
				<>
					<Link className={`${classes.step} ${classes.link} ${status === 'booking' ? classes.outline : ''}`} to={`/booking/${id}/?language=${locale}`}>{tr('Booking summary')}</Link>
					<div className={classes.separator} />
					<Link className={`${classes.step} ${classes.link} ${status === 'checkout' ? classes.outline : ''}`} to={`/checkout/${id}/?language=${locale}`} onClick={trackProceedToCheckout}>{tr('Checkout')}</Link>
				</>
			}
		</div>
	);
}

Progress.propTypes = {
	status: PropTypes.string.isRequired,
	id: PropTypes.string,
};

export default Progress;

const useStyles = makeStyles(theme => ({
	progress: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	step: {
		textDecoration: 'none',
		[theme.breakpoints.down('sm')]: {
			padding: `0 ${theme.spacing(1)}px`,
			margin: `0 ${theme.spacing(1)}px`,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: '20px',
		},
	},
	link: {
		color: theme.palette.primary.light,
		'&:hover': {
			color: theme.palette.custom.main,
		},
	},
	outline: {
		fontWeight: 600,
		color: theme.palette.custom.main,
	},
	error: {
		fontWeight: 600,
		color: theme.palette.error.main,
	},
	separator: {
		height: '3px',
		width: '40px',
		background: theme.palette.custom.separator,
		margin: `0 ${theme.spacing(3)}px`,

		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}));
