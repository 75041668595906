const Yup = require('yup');

export const requestFormSchema = () => {

	return Yup.object().shape({
		name: Yup.string().required('Required'),
		surname: Yup.string().required('Required'),
		email: Yup.string().email('Must be an email').required('Required'),
		phone: Yup.string().required('Required'),
		company: Yup.boolean(),
		companyName: Yup.string()
			.when('company', {
				is: true,
				then: Yup.string().required('Required'),
			}),
		agreeMarketingInfo: Yup.boolean().oneOf([true], 'Required'),
	});
};
