import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

function Amenity({ amenity, large }) {
	const classes = useStyles();

	const { locale } = useSelector(state => state.locale);

	return (
		<div className={`${classes.amenity} ${large ? classes.large : ''}`}>
			<div className={`${classes.inner} ${large ? classes.background : ''}`}>
				<div className={classes.image}>
					{amenity.imageId && <img src={`/images/${amenity.imageId}`} className={classes.img} />}
				</div>
				<div className={classes.label}>
					{amenity?.i18n[locale]?.title || amenity.title}
				</div>
			</div>
		</div>
	);
}

Amenity.propTypes = {
	amenity: PropTypes.object.isRequired,
	large: PropTypes.bool,
};

export default Amenity;

const useStyles = makeStyles(theme => ({
	amenity: {
		width: '25%',
		padding: theme.spacing(1),
	},
	large: {
		width: '50%',
		[theme.breakpoints.up('sm')]: {
			width: '33.33%',
		},
	},
	inner: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px 0`,
	},
	background: {
		backgroundColor: theme.palette.custom.background,
		padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
	},
	image: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		marginBottom: theme.spacing(1),
	},
	img: {
		maxWidth: '100%',
		height: 'auto',
	},
	label: {
		whiteSpace: 'nowrap',
		width: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		textAlign: 'center',
		fontSize: '14px',
	},
}));
