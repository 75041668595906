import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '@redux/actions';

import { useTranslation } from '@common/utils';

import { getImagePath } from '@common/services/imageService';

import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Menu, MenuItem, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	avatar: {
		width: '50px',
		height: '50px',
		lineHeight: '51px',
		backgroundColor: '#C4C4C4',
		borderRadius: '2px',
		color: theme.palette.primary.dark,
		fontSize: '42px',
		fontWeight: '600',
	},
	pointer: {
		cursor: 'pointer',
	},
}));

function NavigationLogo() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const tr = useTranslation();

	const user = useSelector(user => user.session);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpen = event => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const handleLogout = () => {
		handleClose();

		logoutAction()
			.then(dispatch).catch(console.error);
	};

	if (!user.virtual) {
		return (
			<Avatar src={user.client.logoId ? getImagePath(user.client.logoId) : ''} alt={user.client.title} className={classes.avatar}>{user.client.title ? user.client.title.charAt(0).toUpperCase() : ''}</Avatar>
		);
	}

	return (
		<>
			<Tooltip title={user.client.title} placement="right">
				<Avatar onClick={handleOpen} src={user.client.logoId ? getImagePath(user.client.logoId) : ''} alt={user.client.title} className={`${classes.avatar} ${classes.pointer}`}>{user.client.title ? user.client.title.charAt(0).toUpperCase() : ''}</Avatar>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				elevation={0}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem onClick={handleLogout}>{tr('Exit client management')}</MenuItem>
			</Menu>
		</>
	);
}

export default NavigationLogo;
