import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Drawer, List } from '@material-ui/core';

import NavigationItem from './NavigationItem';
import NavigationLogo from './NavigationLogo';

import {
	UsersAltIcon,
	OfficesIcon,
	ReservationsIcon,
	ResourcesIcon,
	IssuesIcon,
	QRCodesIcon,
	SettingsIcon,
} from '@common/components/Icon';

const useStyles = makeStyles(theme => ({
	drawer: {
		flexShrink: 0,
		overflowX: 'hidden',
		width: theme.spacing(10),
		border: '10px solid red',
	},
	paper: {
		width: theme.spacing(10),
		background: theme.palette.primary.dark,
		borderRight: 0,
		display: 'flex',
		justifyContent: 'space-between',
	},
	header: {
		display: 'flex',
		flex: '0 1 80px',
		justifyContent: 'center',
		alignItems: 'center',
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
		justifyContent: 'center',
	},
	icon: {
		width: 28,
		height: 28,
	},
}));

function Navigation({ activeSection }) {
	const classes = useStyles();

	return (
		<Drawer
			className={classes.drawer}
			variant="permanent"
			anchor="left"
			classes={{
				paper: clsx(classes.paper),
			}}>
			<div className={classes.header}>
				<NavigationLogo />
			</div>
			<List component="nav" className={classes.main}>
				<NavigationItem active={activeSection} section={'reservations'} sections={['reservation', 'reservations']}>
					<ReservationsIcon className={classes.icon} />
				</NavigationItem>
				<NavigationItem active={activeSection} section={'offices'} sections={['office', 'offices']}>
					<OfficesIcon className={classes.icon} />
				</NavigationItem>
				<NavigationItem active={activeSection} section={'users'} sections={['user', 'users']}>
					<UsersAltIcon className={classes.icon} />
				</NavigationItem>
				<NavigationItem active={activeSection} section={'resources'} sections={['resource', 'resources', 'resource-group']}>
					<ResourcesIcon className={classes.icon} />
				</NavigationItem>
				<NavigationItem active={activeSection} section={'issues'} sections={['issue', 'issues']}>
					<IssuesIcon className={classes.icon} />
				</NavigationItem>
				<NavigationItem active={activeSection} section={'qr-codes'} sections={['qr-code', 'qr-codes']}>
					<QRCodesIcon className={classes.icon} />
				</NavigationItem>
				<NavigationItem active={activeSection} section={'settings'} >
					<SettingsIcon className={classes.icon} />
				</NavigationItem>
			</List>
		</Drawer>
	);
}

Navigation.propTypes = {
	activeSection: PropTypes.string.isRequired,
};

export default Navigation;
