import { createStore, applyMiddleware, compose } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import reducers from './reducers';

export default initialState => {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	return createStore(
		reducers,
		initialState,
		composeEnhancers(applyMiddleware(reduxImmutableStateInvariant())),
	);
};
