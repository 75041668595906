import React from 'react';
import PropTypes from 'prop-types';

import GoogleMapReact from 'google-map-react';

import { makeStyles } from '@material-ui/core/styles';

import { useSettings } from '@common/hooks';

const AnyReactComponent = () => {
	const classes = useStyles();

	return (
		<div className={classes.icon} />
	);
};

const useStyles = makeStyles(theme => ({
	icon: {
		background: '#fff',
		height: '30px',
		width: '30px',
		borderRadius: '50%',
		transform: 'translate(-50%, -50%)',
		border: `6px solid ${theme.palette.primary.main}`,
	},
}));

const GoogleMap = ({ coordinates, zoom = 16 }) => {
	const location = {
		lat: 54.6872,
		lng: 25.2797,
	};

	const { googleMapsAPIKey } = useSettings();

	const coords = coordinates.split(',');

	const lat = Number(coords[0]);
	const lng = Number(coords[1]);

	if (!isNaN(lat) && !isNaN(lng)) {
		location.lat = lat;
		location.lng = lng;
	}

	if (!googleMapsAPIKey) {
		console.warn('GoogleMap: missing google maps api key');
		return null;
	}

	return (
		<GoogleMapReact
			bootstrapURLKeys={{ key: googleMapsAPIKey }}
			defaultCenter={location}
			defaultZoom={zoom}
		>
			<AnyReactComponent
				lat={location.lat}
				lng={location.lng}
				text=""
			/>
		</GoogleMapReact>
	);
};


GoogleMap.propTypes = {
	coordinates: PropTypes.string,
	zoom: PropTypes.number,
};

export default GoogleMap;
