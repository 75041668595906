import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';

import { ColivingCard } from '@common/components';

function ColivingGroup({ group, filter }) {
	const classes = useStyles();

	return (
		<div className={classes.group}>
			<Grid container spacing={3}>
				{group.colivings.sort((a, b) => a.pricePerDay - b.pricePerDay).map((coliving, i) => {
					return (
						<Grid key={`coliving-${i}`} item xs={12} md={4} container>
							<ColivingCard coliving={coliving} filter={filter} />
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
}

ColivingGroup.propTypes = {
	group: PropTypes.object,
	filter: PropTypes.object,
};

export default ColivingGroup;

const useStyles = makeStyles(theme => ({
	group: {
		paddingBottom: theme.spacing(5),
	},
}));
