export default {
	'Search': 'Search',
	'Check in': 'Check in',
	'Select Date': 'Select Date',
	'Check Out': 'Check Out',
	'Terms and Conditions': 'Terms and Conditions',
	'Privacy Policy': 'Privacy Policy',
	'Book a co-working space': 'Book a co-working space',
	'day': 'day',
	'guests': 'guests', // eg "100 guests"
	'access to coworking': 'access to coworking',
	'Description': 'Description',
	'Free cancellation': 'Free cancellation',
	'Location': 'Location',
	'Add dates for prices': 'Add dates for prices',
	'Total': 'Total',
	'Inquire': 'Inquire',
	'Book Now': 'Book Now',
	'Room': 'Room',
	'Request for availability': 'Request for availability',
	'Please fill contact form': 'Please fill contact form',
	'Refine your booking': 'Refine your booking',
	'Your booking details': 'Your booking details',
	'Booking summary': 'Booking summary',
	'Checkout': 'Checkout',
	'Need more products?': 'Need more products?',
	'Add product': 'Add product',
	'Add Room': 'Add room',
	'Add room': 'Add room',
	'We will hold your reservation until you add all rooms you need.': 'We will hold your reservation until you add all rooms you need.',
	'We will hold your reservation until you add all the products you need.': 'We will hold your reservation until you add all the products you need.',
	'Summary': 'Summary',
	'Services': 'Services',
	'Proceed to checkout': 'Proceed to checkout',
	'Check-in': 'Check-in',
	'Check-out': 'Check-out',
	'Rooms': 'Rooms',
	'room': 'room',
	'Quantity': 'Quantity',
	'Price (taxes incl.)': 'Price (taxes incl.)',
	'remove reservation': 'remove reservation',
	'Contact information': 'Contact information',
	'Name': 'Name',
	'Last name': 'Last name ',
	'Email': 'Email',
	'Your phone': 'Your phone',
	'Company': 'Company',
	'Company code': 'Company code',
	'Company address': 'Company address',
	'Company VAT code': 'Company VAT code',
	'Have a discount code?': 'Have a discount code?',
	'Discount code': 'Discount code',
	'Apply': 'Apply',
	'I accept': 'I accept',
	'and': 'and',
	'I agree to get marketing info. I may unsubscribe anytime.': 'I agree to get marketing info. I may unsubscribe anytime.',
	'Pay': 'Pay',
	'Booking details': 'Booking details',
	'VAT': 'VAT',
	'Total (taxes incl.)': 'Total (taxes incl.)',
	'Check-Out': 'Check-Out',
	'Required': 'Required',
	'Must be an email': 'Must be an email',
	'It appears that this discount code is invalid.': 'It appears that this discount code is invalid.',
	'Discount code applied': 'Discount code applied',
	'It is not available during selected dates': 'It is not available during selected dates',
	'Request': 'Request',
	'Failed reservation': 'Failed reservation',
	'There was a problem and we were not able to complete your reservation order. Possible reasons why order failed are:': 'There was a problem and we were not able to complete your reservation order. Possible reasons why order failed are:',
	'a billing error caused by your bank': 'a billing error caused by your bank',
	'insufficient credit on your account': 'insufficient credit on your account',
	'your credit card is expired': 'your credit card is expired',
	'booking was cancelled or expired': 'booking was cancelled or expired',
	'Your booking reservation': 'Your booking reservation',
	'Booking confirmation was sent to you by email at': 'Booking confirmation was sent to you by email at',
	'Hello there,': 'Hello there,',
	'Thank you for booking with us. We are looking forward to welcoming you.': 'Thank you for booking with us. We are looking forward to welcoming you.',
	'If you have any questions or preferences for your stay please get in touch': 'If you have any questions or preferences for your stay please get in touch',
	'Your payment failed': 'Your payment failed',
	'Unfortunately, we couldn\'t collect payment on your purchase. Please take a moment to review your billing information - if it looks correct, please': 'Unfortunately, we couldn\'t collect payment on your purchase. Please take a moment to review your billing information - if it looks correct, please',
	'to let us know. Otherwise, please update your information': 'to let us know. Otherwise, please update your information',
	'f you have any questions about your checkout,': 'f you have any questions about your checkout,',
	'and we\'ll get right back to you.': 'and we\'ll get right back to you.',
	'Upon arrival': 'Upon arrival',
	'here to find us': 'here to find us',
	'There are plenty of public parking places at the location.': 'There are plenty of public parking places at the location.',
	'NotFound': 'Resource not found',
	'BookingOneOrMoreProductsUnavailable': 'No available beds in room',
	'CoworkingOrConferenceRoomBookingOneOrMoreProductsUnavailable': 'No available room',
	'UnexpectedError': 'Oops, we messed up. We are deeply sorry, but sometimes things just fall apart. You might want to try again. Please? :)',
	'DuplicateOrder': 'It seems that your order has been submitted already! We don\'t want to charge you for more than you have ordered already :)',
	'BookingExpired': 'It seems that your booking has expired, please, start over.',
	'Amenities': 'Amenities',
};
