export default {
	'Search': 'Wyszukiwanie',
	'Season': 'Sezon',
	'Select season': 'Wybierz sezon',
	'Back to search': 'Wróć do wyszukiwania',
	'Check in': 'Zameldowanie',
	'Check Out': 'Wymeldowanie',
	'Privacy Policy': 'Polityka prywatności',
	'Terms and Conditions': 'Warunki',
	'Book a co-living space': 'Zarezerwuj miejsce w przestrzeni co-living',
	'Minimum duration': 'Minimalny okres',
	'Maximum duration': 'Maksymalny okres',
	'day': 'dzień',
	'Days': 'Dni',
	'days': 'dni',
	'week': 'tydzień',
	'weeks': 'tygodnie',
	'month': 'miesiąc',
	'months': 'miesiące',
	'January': 'styczeń',
	'February': 'luty',
	'March': 'marzec',
	'April': 'kwiecień',
	'May': 'maj',
	'June': 'czerwiec',
	'July': 'lipiec',
	'August': 'sierpień',
	'September': 'wrzesień',
	'October': 'październik',
	'November': 'listopad',
	'December': 'grudzień',
	'Minimum stay is': 'Minimalny okres pobytu to',
	'Description': 'Opis',
	'Free cancellation': 'Bezpłatne anulowanie',
	'Location': 'Lokalizacja',
	'Getting here': 'Dojazd',
	'Add dates for prices': 'Wybierz daty aby zobaczyć ceny',
	'Refine your booking': 'Zawęź wyszukiwanie',
	'Total': 'Razem',
	'Inquire': 'Dowiedz się',
	'Book Now': 'Zarezerwuj teraz',
	'Room': 'Pokój',
	'room': 'pokój',
	'Rooms': 'Pokoje',
	'Request for availability': 'Wyślij zapytanie o dostępność',
	'Extras and upgrades': 'Usługi dodatkowe i zmiany rezerwacji',
	'Extras': 'Usługi dodatkowe',
	'unit': 'lokal',
	'remove': 'usuń',
	'Booking summary': 'Podsumowanie rezerwacji',
	'Checkout': 'Finalizacja rezerwacji',
	'Proceed to checkout': 'Sfinalizuj rezerwację',
	'Need more rooms?': 'Potrzebujesz więcej pokoi?',
	'Add Room': 'Dodaj pokoje',
	'We will hold your reservation until you add all rooms you need.': 'Wstrzymamy Twoją rezerwację do momentu dodania wszystkich pokoi.',
	'person': 'osoba',
	'Amenities': 'Udogodnienia',
	'City tax': 'Podatek miejski',
	'Contact information': 'Dane kontaktowe',
	'Services': 'Usługi',
	'Male': 'Mężczyzna',
	'Female': 'Kobieta',
	'Other': 'Inna',
	'Study': 'Nauka',
	'Work': 'Praca',
	'Leisure': 'Rozrywka',
	'Name': 'Imię',
	'Last name': 'Nazwisko',
	'Email': 'Adres e-mail',
	'Your phone': 'Numer telefonu',
	'Address': 'Adres zamieszkania',
	'Gender': 'Płeć',
	'Stay purpose': 'Cel pobytu',
	'University': 'Uniwersytet',
	'Study year': 'Rok studiów',
	'Faculty': 'Wydział',
	'Type of study program': 'Rodzaj studiów',
	'Country': 'Kraj',
	'Date of birth': 'Data urodzenia',
	'Company': 'Firma',
	'Company code': 'Numer identyfikacyjny firmy',
	'Company address': 'Adres firmy',
	'Company VAT code': 'Numer identyfikacyjny VAT firmy',
	'Have a discount code?': 'Czy posiadasz kod zniżkowy?',
	'Discount code': 'Kod zniżkowy',
	'Apply': 'Zastosuj',
	'I accept': 'Wyrażam zgodę',
	'and': 'oraz',
	'I agree to get marketing info.': 'Zgadzam się na otrzymywanie materiałów reklamowych',
	'I agree to get marketing info. I may unsubscribe anytime.': 'Zgadzam się na otrzymywanie materiałów reklamowych. Mam prawo zrezygnować z subskrypcji w każdej chwili.',
	'Pay': 'Zapłać',
	'Booking details': 'Dane rezerwacji',
	'VAT': 'VAT',
	'Total (taxes incl.)': 'Razem (w tym podatki)',
	'Required': 'Wymagane',
	'It appears that this discount code is invalid.': 'Wygląda na to że podany kod zniżkowy jest nieważny',
	'Must be an email': 'Musi być adresem e-mail',
	'Discount code applied.': 'Zastosowano kod zniżkowy.',
	'Discount code applied': 'Zastosowano kod zniżkowy',
	'It is not available during selected dates': 'Nie jest dostępny w wybranym zakresie dat',
	'Request': 'Zapytanie',
	'Failed reservation': 'Rezerwacja nie powiodła się',
	'There was a problem and we were not able to complete your reservation order. Possible reasons why order failed are': 'Wystąpił problem i nie udało się dokończyć rezerwacji. Możliwe powody niepowodzenia rezerwacji to',
	'a billing error caused by your bank': 'błąd rozliczeń ze strony banku',
	'insufficient credit on your account': 'niewystarczające środki pieniężne na koncie',
	'your credit card is expired': 'Twoja karta kredytowa straciła ważność',
	'booking was cancelled or expired': 'rezerwacja została anulowana lub wygasła',
	'Total paid': 'Razem zapłacono',
	'Total due (taxes incl.)': 'Razem do zapłaty (w tym podatki)',
	'Check-in': 'Zameldowanie',
	'Check-out': 'Wymeldowanie',
	'Check-Out': 'Wymeldowanie',
	'Minimum stay duration': 'Minimalny okres pobytu',
	'Maximum stay duration': 'Maksymalny okres pobytu',
	'It seems that your booking has expired, please, start over.': 'Wygląda na to, że rezerwacja wygasła, prosimy zacząć od nowa',
	'Exchange (Erasmus 5 months)': 'Wymiana (Erasmus, 5 miesięcy)',
	'Exchange (Erasmus 10 months)': 'Wymiana (Erasmus, 10 miesięcy)',
	'Full-time': 'Stacjonarnie',
	'InvalidId': 'NieważnyIdentyfikator',
	'BookingProductEmptyFromToDates': 'ProduktRezerwacjiNieWypełnionoDatOdDo',
	'InvalidBooking': 'NieważnaRezerwacja',
	'BookingExpired': 'RezerwacjaWygasła',
	'BookingHasUnavailableProducts': 'RezerwacjaZawieraNiedostępneProdukty',
	'BookingAlreadyCheckedOut': 'RezerwacjaZostałaJużOpłacona',
	'BookingAlreadyDeleted': 'RezerwacjaZostałaJużAnulowana',
	'BookingExpectedAllBookingProductsToHaveReservation': 'OczekiwanieNaRezerwacjęWszystkieRezerwowaneProduktyMusząByćObjęteRezerwacją',
	'ColivingBookingCheckoutMissingDeliveryMethod': 'ColivingFinalizacjaRezerwacjiBrakMetodyDostarczenia',
	'BookingOneOrMoreProductsUnavailable': 'RezerwacjaNiedostępnyJedenLubWięcejProduktów',
	'DurationIsTooShort': 'PobytJestZbytKrótki',
	'DurationIsTooLong': 'PobytJestZbytDługi',
	'DurationIsNegativeOrZero': 'PobytToLiczbaUjemnaLubZero',
	'InvalidDate': 'NieprawidłowaData',
	'DuplicateOrder': 'DuplikatZamówienia',
	'BookingNothingToInvoice': 'RezerwacjaBrakElementówDoOpłacenia',
	'BookingShouldHaveAtLeastOneBookableProduct': 'RezerwacjaPowinnaZawieraćCoNajmniejJedenMożliwyDoZarezerowaniaProdukt',
	'IncorrectColivingBookinCheckOutDate': 'NieprawidłowaDataWymeldowaniaZPrzestrzeniColiving',
	'InvalidAvailabilityRangeExceedsTwoCalendarMonths': 'NieprawidłowyZakresDostępnościPrzekraczaDwaMiesiąceKalendarzowe',
	'PayseraIntegrationDisabled': 'IntegracjaZSystememPayseraWyłączona',
	'CheckoutCreationFailed': 'NiepowodzenieWygenerowaniaStronyPłatności',
	'CheckoutPriceCalculationFailed': 'NiepowodzenieWyliczeniaCenyDoZapłaty',
	'NotFound': 'NieZnaleziono',
	'ValidationError': 'BłądWeryfikacji',
	'Hello there': 'Witamy',
	'Your booking has been updated. Please, review the newly added items and proceed with a follow-up payment.': 'Twoja rezerwacja została zaktualizowana. Prosimy sprawdzić nowo dodane pozycje i dokonać dodatkowej płatności.',
	'Your booking reservation': 'Twoja rezerwacja',
	'Customer': 'Klient',
	'Your order online': 'Twoje zamówienie online',
	'Added items': 'Dodane pozycje',
	'Beds': 'Łóżka',
	'Quantity': 'Liczba',
	'Night': 'Noc',
	'Book again': 'Zarezerwuj ponownie',
	'Booking updated': 'Rezerwacja zaktualizowana',
	'Thanks for booking.': 'Dziękujemy za dokonanie rezerwacji.',
	'Booking confirmation': 'Potwierdzenie rezerwacji',
	'Click here to View Order online': 'Kliknij tutaj, aby zobaczyć swoje zamówienie online',
	'Discount': 'Zniżka',
	'Deposit': 'Depozyt',
	'Due Today': 'Do zapłaty dzisiaj',
	'Season gaps are included': 'Uwzględniono przerwy w sezonie',
	'Booking can be made only for a continuous period. If you want to make a reservation for nonconsecutive seasons you need to make separate reservations': 'Rezerwacji można dokonać jedynie na okres ciągły. Jeśli chcesz dokonać rezerwacji na okres przerywany, musisz dokonać kilku osobnych rezerwacji',
	'Submit': 'Prześlij',
	'Cancel': 'Anuluj',
	'Please fill contact form': 'Prosimy uzupełnić formularz kontaktowy',
	'Add': 'Dodaj',
	'Instructions were sent to': 'Instrukcje wysłano do',
	'Fees': 'Opłaty',
	'It is not available during selected season.': 'Nie jest dostępny w wybranym sezonie.',
	'Thank you, your inquiry has been received and is being processed. Someone will contact you as soon as possible!': 'Dziękujemy, otrzymaliśmy Twoje zapytanie i rozpatrujemy je. Skontaktujemy się z Tobą najszybciej, jak to możliwe!',
	'Back to main page after': 'Powrót do strony głównej za',
	'seconds': 'sekund',
	'Price (taxes incl.)': 'Cena (z podatkami)',
	'remove reservation': 'Usuń rezerwację',
	'Your booking details': 'Dane Twojej rezerwacji',
	'Summary': 'Podsumowanie',
	'Accept': 'zaakceptować',
	'Hours': 'Godziny',
	'Reservation removed successfully': 'Rezerwacja została pomyślnie usunięta',
	'Your booking expired': 'Twoja rezerwacja wygasła',
	'Search for Room': 'Wyszukaj pokój',
	'We hold bookings for 15 minutes.': 'Rezerwacje utrzymujemy przez 15 minut.',
	'Upon arrival': 'Po przyjeździe',
	'bed': 'łóżko',
	'Nights': 'Noce',
	'nights': 'noce',
	'Due today': 'Do zapłaty dzisiaj',
	'Deposits': 'Depozyt',
};
