import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { HeaderMessages } from '@components/Coliving';
import { DateFilter } from './components';
import { Header, Container, ColivingCard, Footer } from '@common/components';

import { usePageFilter, useRecords } from '@common/utils';

console.log('GTM_ID', Array.from(document.querySelectorAll('noscript')).map(e=>e.textContent).find(e => e.includes('id=GTM'))?.match(/(?:GTM-[^ "]*)/)[0]); // eslint-disable-line no-console

function ColivingList({ match }) {
	const classes = useStyles();

	const { filter, setFilter } = usePageFilter({
		from: null,
		to: null,
		location: (new URLSearchParams(window.location.search)).get('location') || undefined,
	});

	const { records } = useRecords('/api/kiosks/coworking/products', filter, [match.url]);
	const recordsFlat = records.map(r => r.colivings).flat(1);

	const colivingsSorted = _.sortBy(recordsFlat,
		i => (i.available || Infinity),
		i => (i.sortOrder !== undefined ? i.sortOrder : (i.isOnlyInquired === true ? -1 : Infinity)),
		i => (i.pricePerDay || Infinity),
	);

	return (
		<div className={classes.root}>
			<HeaderMessages />
			<Header>
				<DateFilter filter={filter} setFilter={setFilter} />
			</Header>

			<Container style={{ display: 'flex', flexDirection: 'column' }}>
				<Grid container spacing={2} style={{ paddingBottom: '100px' }}>
					{
						colivingsSorted.map((coliving, i) => (
							<Grid key={`coliving-${i}`} item xs={12} md={4} container>
								<ColivingCard
									coliving={coliving}
									filter={filter}
								/>
							</Grid>
						))}
				</Grid>
			</Container>
			<Footer />
		</div>
	);
}

ColivingList.propTypes = {
	match: PropTypes.object,
};

export default ColivingList;

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		position: 'relative',
		minHeight: '96vh',
	},
}));

