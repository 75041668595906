import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import { useTranslation } from '@common/utils';
import { useSettings } from '@common/hooks';

import moment from 'moment';
import { useDetails } from '../../../../ui-common/utils';

function CheckoutSummary({ booking, pricing }) {
	const classes = useStyles();
	const tr = useTranslation();
	const { locale } = useSelector(state => state.locale);
	const { currencySymbol } = useSettings();

	const { totalBeds, totalRooms, totalServices, totalFees } = booking;
	const {
		total,
		totalVAT,
		VATLines,
		discountWithCodeInfo: {
			appliedAmount,
			code,
		},
	} = pricing;

	const getDaysCount = (from, to) => {
		if (!from || !to) {
			return;
		}

		const start = moment(from).subtract(1, 'day');
		const end = moment(to);

		let numOfDays = 0;

		while (start.format('YYYYMMDD') < end.format('YYYYMMDD')) {
			numOfDays++;
			start.add(1, 'day');
		}

		return numOfDays;
	};

	const getHoursCount = (from, to) => {
		if (!from || !to) {
			return;
		}

		const duration = moment.duration(moment(to).add(1, 's').diff(from));
		const hours = duration.hours();

		return hours;
	};

	return (
		<>
			<div className={classes.summary}>
				<Typography variant="h3">
					{tr('Booking details')}
				</Typography>

				{booking.productsGrouped.map((p, i) => {

					const { details: product } = useDetails(`/api/kiosks/coworking/products/${p.product}`);

					if (!product) {
						return;
					}

					const isDorm = p.productAssetType === 'dorm';

					return (
						<div key={`product-${i}`}>
							<div className={classes.product}>
								<Typography variant="h4" className={classes.headline}>
									{product?.i18n[locale]?.title || product?.title || '-'}
								</Typography>
								{p.from && p.to && <div className={classes.dates}>
									<div>
										<b>{tr('Check-in')}</b>
										&nbsp;
										{moment(p.from).format(p.durationPeriod === 'hours' ? 'YYYY-MM-DD HH:mm' : 'MMM DD, YYYY')}
									</div>
									<div>
										<b>{tr('Check-Out')}</b>
										&nbsp;
										{moment(p.to).add(p.durationPeriod === 'hours' ? 1 : 0, 'ms').format(p.durationPeriod === 'hours' ? 'YYYY-MM-DD HH:mm' : 'MMM DD, YYYY')}
									</div>
								</div>}
							</div>


							<div className={classes.rows}>
								<div className={classes.row}>
									<div>1 {tr(isDorm ? 'bed' : 'room')}</div>
									<div>{p.price} {currencySymbol}</div>
								</div>

								<div className={`${classes.row} ${classes.rowQTY}`}>
									<div>{tr('Quantity')}</div>
									<div>{p.quantity}</div>
								</div>

								<div className={`${classes.row} ${classes.rowQTY}`}>
									<div>{tr(p.durationPeriod === 'hours' ? 'Hours' : 'Days')}</div>
									<div>{p.durationPeriod === 'hours' ? getHoursCount(p.from, p.to) : getDaysCount(p.from, p.to)}</div>
								</div>

								<div className={classes.separator} />
								<div className={classes.row}>
									<div>{tr('Services')}</div>
									<div>{p.totalServices} {currencySymbol}</div>
								</div>

								<div className={classes.separator} />
								<div className={`${classes.row} ${classes.total}`}>
									<div>
										{tr('Total')}
									</div>
									<div>
										{p.total} {currencySymbol}
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className={classes.summary}>
				<Typography variant="h3">
					{tr('Booking summary')}
				</Typography>

				<div className={classes.rows}>

					{totalRooms > 0 &&
						<>
							<div className={classes.row}>
								<div>{tr('Rooms')}</div>
								<div>{totalRooms} {currencySymbol}</div>
							</div>
							<div className={classes.separator} />
						</>
					}

					{totalBeds > 0 &&
						<>
							<div className={classes.row}>
								<div>{tr('Beds')}</div>
								<div>{totalBeds} {currencySymbol}</div>
							</div>
							<div className={classes.separator} />
						</>
					}

					{totalFees > 0 &&
						<>
							<div className={classes.row}>
								<div>{tr('Fees')}</div>
								<div>{totalFees} {currencySymbol}</div>
							</div>
							<div className={classes.separator} />
						</>
					}

					<div className={classes.row}>
						<div>{tr('Services')}</div>
						<div>{totalServices} {currencySymbol}</div>
					</div>

					<div className={classes.separator} />

					{totalVAT >= 0 && VATLines.map(vat => <div key={vat.percent} className={`${classes.row}`}>
						<div>
							{`${tr('VAT')} (${vat.percent}%)`}
						</div>
						<div>
							{vat.amount.toFixed(2)} {currencySymbol}
						</div>
					</div>)}
					{code && appliedAmount >= 0 && <div className={`${classes.row} ${classes.total}`}>
						<div>
							{tr(`Discount (${code})`)}
						</div>
						<div>
							&minus;{appliedAmount} {currencySymbol}
						</div>
					</div>}
					<div className={`${classes.row} ${classes.total}`}>
						<Typography variant="h5">
							{tr('Total (taxes incl.)')}
						</Typography>
						<Typography variant="h5">
							{total} {currencySymbol}
						</Typography>
					</div>
				</div>
			</div>
		</>
	);
}

CheckoutSummary.propTypes = {
	booking: PropTypes.object.isRequired,
	pricing: PropTypes.object.isRequired,
};

export default CheckoutSummary;

const useStyles = makeStyles(theme => ({
	summary: {
		marginBottom: theme.spacing(5),
		padding: `${theme.spacing(4)}px ${theme.spacing(3)}px 0`,
		borderRadius: '4px',
		backgroundColor: theme.palette.custom.background,
	},
	rows: {
		padding: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: `${theme.spacing(2)}px 0`,
	},
	rowQTY: {
		paddingTop: 0,
	},
	total: {
		fontWeight: 600,
	},
	separator: {
		height: '1px',
		backgroundColor: theme.palette.custom.separator,
	},

	headline: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	product: {
		// marginBottom: theme.spacing(3),
	},

	section: {
		paddingLeft: 0,
		fontSize: '18px',
		color: theme.palette.primary.light,
		fontWeight: 600,
	},
	col: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		borderBottom: 0,
	},
	first: {
		paddingLeft: 0,
	},
	last: {
		paddingRight: 0,
	},
	sep: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	separator2: {
		height: '1px',
		backgroundColor: theme.palette.custom.separator,
	},

}));
