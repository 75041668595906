export const getAdyenConfiguration = () => ({
	environment: 'test',
	clientKey: '',
	analytics: {
		enabled: false,
	},
	session: {
		id: '', // Unique identifier for the payment session - received from BE.
		sessionData: '', // The payment session data - received from BE.
	},
	onPaymentCompleted: (result, component) => {
	},
	onError: (error, component) => {
	},
	// Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
	// For example, this is 3D Secure configuration for cards:
	paymentMethodsConfiguration: {
		card: {
			hasHolderName: true,
			holderNameRequired: true,
			billingAddressRequired: true,
		},
	},
});
