import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, FormControlLabel, FormControl, Checkbox, FormGroup, FormHelperText, Backdrop, CircularProgress } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';

import http from '@common/http';
import { Header, Container, Button, Footer } from '@common/components';
import { CheckoutSummary } from './components';
import { HeaderMessages, Progress, TermsAndConditionsConfirmDialog } from '@components/Coliving';
import { toast, useDetails, useSessionState, useTranslation } from '@common/utils';
import checkoutSchema from '@schemas/coliving/checkout';

import { useSettings } from '@common/hooks';

import { getAdyenConfiguration } from '@shared/services/checkout';

const adyenBlankConfiguration = getAdyenConfiguration();

function ColivingCheckout({ match, history }) {
	const classes = useStyles();
	const { id } = match.params;

	const {
		paymentGatewaySettings,
	} = useSettings();

	const tr = useTranslation();

	const { locale } = useSelector(state => state.locale);

	const { setState: setBookingId } = useSessionState('booking-id', '');
	const { state: bookingGuid, setState: setBookingGuid } = useSessionState('booking-guid', uuidv4());
	const [payseraRequest, setPayseraRequest] = useState(null);
	const [discountCode, setDiscountCode] = useState('');
	const [appliedDiscountCode, setAppliedDiscountCode] = useState('');
	const [isAppliedDiscountCodeValid, setAppliedDiscountCodeValidity] = useState();
	const [pricing, setPricing] = useState();
	const [isAdditionalPaymentStepUsed, setIsAdditionalPaymentStepUsed] = useState(false);
	const [adyenConfiguration, setAdyenConfiguration] = useState(adyenBlankConfiguration);
	const [adyenPaymentSuccess, setAdyenPaymentSuccess] = useState(undefined);
	const [isBackdropOpen, setBackdropOpen] = useState(false);
	const [isAcceptTermsConditions, setIsAcceptTermsConditions] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

	const payseraFormRef = useRef();

	const [customer, setCustomer] = useState({
		name: '',
		surname: '',
		email: '',
		phone: '',
		companyName: '',
		companyCode: '',
		companyAddress: '',
		companyVATCode: '',
		acceptsTermsConditions: false,
		agreeMarketingInfo: false,
	});

	const { details: booking } = useDetails(`/api/kiosks/coworking/bookings/${id}`);

	const calculateTotals = useCallback(async ({ discountCode }) => {
		const { data: { pricing } = {} } = await http.post('/api/kiosks/coworking/checkouts/prices', {
			booking: booking._id,
			discountCode,
		});

		return pricing;
	}, [booking]);

	const submitForm = async data => {
		const body = {
			booking: id,
			customer: {
				name: data.name,
				surname: data.surname,
				phone: data.phone,
				email: data.email,
				agreeMarketingInfo: data.agreeMarketingInfo,
				company: {
					name: data.companyName,
					code: data.companyCode,
					address: data.companyAddress,
					vat: data.companyVATCode,
				},
			},
			discountCode: appliedDiscountCode,
			guid: bookingGuid,
			language: locale,
		};

		const checkout = await http.post('/api/kiosks/coworking/checkouts/', body);

		setBookingId('');
		setBookingGuid('');

		if (checkout?.data?.total === 0) {
			toast({ text: 'Your purchase was a success! Thank You!' });
			history.push(`/order/${id}/success/?language=${locale}`);
		} else if (checkout?.data?.payseraRequest) {
			setPayseraRequest(checkout.data.payseraRequest);
			payseraFormRef.current.submit();
		} else if (checkout?.data?.adyenCheckoutSession) {
			setIsAdditionalPaymentStepUsed(true);
			setAdyenConfiguration(config => ({
				...config,
				session: {
					id: checkout.data.adyenCheckoutSession.id,
					sessionData: checkout.data.adyenCheckoutSession.sessionData,
				},
			}));
		} else if (checkout?.data?.autopayRequest) {
			window.location.replace(checkout.data.autopayRequest.url);
		} else {
			throw Error('UnexpectedError');
		}
	};

	const handleFormSubmit = async (values, actions) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ 'event': 'Pay' });

		try {
			if (appliedDiscountCode) {
				(async () => {
					setPricing(await calculateTotals({ discountCode: appliedDiscountCode }));
				})().catch(e => {
					toast(e);
				});
			}

			await submitForm(values);
		} catch (e) {
			toast(e);

			if (e?.data?.message === 'BookingExpired') {
				history.push('/');
			}

			if (e?.message === 'UnexpectedError' || e?.data?.message === 'DuplicateOrder') {
				setBookingId('');
				setBookingGuid('');
				history.push('/');
			}
		} finally {
			actions.setSubmitting(false);
		}
	};

	const handleDiscountCodeInputChange = e => {
		setDiscountCode(e.target.value);
		setAppliedDiscountCodeValidity();
	};

	const applyDiscountCode = () => {
		(async () => {
			const pricing = await calculateTotals({ discountCode });

			setPricing(pricing);
			setAppliedDiscountCode(pricing.discountWithCodeInfo.code || '');
			setAppliedDiscountCodeValidity(!!pricing.discountWithCodeInfo.code && pricing.discountWithCodeInfo.appliedAmount >= 0);
		})().catch(e => {
			toast(e);
		});
	};

	const handleAcceptTermsAndConditions = () => {
		if (isAcceptTermsConditions) {
			setIsAcceptTermsConditions(false);
			return;
		}
		setIsConfirmModalOpen(true);
	};

	const payseraForm = useMemo(() => payseraRequest != null && (
		<form ref={payseraFormRef} method="POST" action={payseraRequest.url}>
			<input type="hidden" name="data" defaultValue={payseraRequest.data} />
			<input type="hidden" name="sign" defaultValue={payseraRequest.sign} />
		</form>
	), [payseraRequest, payseraFormRef]);

	const onAdyenPaymentCompleted = (result, component) => {
		setAdyenPaymentSuccess(!['Cancelled', 'Error', 'Refused'].includes(result.resultCode));
	};

	const onAdyenPaymentError = (error, component) => {
		setAdyenPaymentSuccess(false);
	};

	useEffect(() => {
		if (!paymentGatewaySettings?.provider) { return; }

		if (paymentGatewaySettings?.provider === 'adyen') {
			setAdyenConfiguration(config => ({
				...config,
				environment: paymentGatewaySettings.adyenEnvironment,
				clientKey: paymentGatewaySettings.adyenClientKey,
				analytics: {
					enabled: paymentGatewaySettings.adyenEnvironment === 'live',
				},
				onPaymentCompleted: onAdyenPaymentCompleted,
				onError: onAdyenPaymentError,
			}));
		}

	}, [paymentGatewaySettings]);

	useEffect(() => {
		if (booking && booking.expired) {
			setBookingId('');
			setBookingGuid('');
		}
	}, [booking, setBookingId, setBookingGuid]);

	useEffect(() => {
		if (booking && !booking.expired) {
			(async () => {
				setPricing(await calculateTotals({ discountCode: appliedDiscountCode }));
			})().catch(e => {
				toast(e);
			});

			if (booking.orders.length) {
				setCustomer(customer => ({
					...customer,
					name: booking.orders[0].customer.name,
					surname: booking.orders[0].customer.surname,
					email: booking.orders[0].customer.email,
					phone: booking.orders[0].customer.phone,
					companyName: booking.orders[0].customer?.company.name,
					companyCode: booking.orders[0].customer?.company.code,
					companyAddress: booking.orders[0].customer?.company.address,
					companyVATCode: booking.orders[0].customer?.company.vat,
				}));
			}
		}
	}, [booking, appliedDiscountCode]);

	useEffect(() => {
		if (!isAdditionalPaymentStepUsed) { return; }
		if (!adyenConfiguration?.clientKey) { return; }
		if (!adyenConfiguration?.session?.sessionData) { return; }
		if (!adyenConfiguration?.session?.id) { return; }

		AdyenCheckout(adyenConfiguration)
			.then(checkout => {
				checkout.create('dropin').mount('#adyen-dropin-container');
			})
			.catch(console.error);
	}, [isAdditionalPaymentStepUsed, adyenConfiguration]);

	useEffect(() => {
		if (id && typeof adyenPaymentSuccess === 'boolean') {
			const checkoutResultUrl = `/order/${id}/?language=${locale}`;

			setBackdropOpen(true);

			new Promise(resolve => setTimeout(resolve, 2000))
				.then(() => {
					setBackdropOpen(false);

					if (adyenPaymentSuccess === true) {
						history.push(`${checkoutResultUrl}/success/?language=${locale}`);
					} else {
						history.push(`${checkoutResultUrl}/failure/?language=${locale}`);
					}
				})
				.catch(console.error);
		}
	}, [id, adyenPaymentSuccess]);

	return (
		<div className={classes.root}>
			<HeaderMessages />
			<Header>
				<Progress status="checkout" id={id} />
			</Header>

			<Container>
				<Grid container spacing={3} style={{ paddingBottom: '100px' }}>
					{!isAdditionalPaymentStepUsed && <Grid item xs={12} md={8}>
						<Formik
							enableReinitialize={true}
							initialValues={customer}
							validationSchema={checkoutSchema(tr)}
							onSubmit={handleFormSubmit}
							validateOnChange={false} >
							{({
								values,
								handleChange,
								handleBlur,
								touched,
								errors,
								setFieldValue,
							}) => {
								const props = (name, initial = '') => ({
									name: name,
									value: typeof values[name] !== 'undefined' ? values[name] : initial,
									onChange: handleChange,
									onBlur: handleBlur,
									error: touched[name] && Boolean(errors[name]),
									helperText: touched[name] ? tr(errors[name]) : '',
								});

								return (
									<Form>
										<Typography variant="h3" className={classes.headline}>
											{tr('Contact information')}
										</Typography>

										<Grid container spacing={3} className={classes.form}>
											<Grid item xs={12} sm={6}>
												<TextField fullWidth required variant="outlined" label={tr('Name')} {...props('name')} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField fullWidth required variant="outlined" label={tr('Last name')} {...props('surname')} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField fullWidth required variant="outlined" label={tr('Email')} {...props('email')} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField fullWidth required variant="outlined" label={tr('Your phone')} {...props('phone')} />
											</Grid>
											<Grid item xs={12}>
												<FormControl component="fieldset">
													<FormGroup>
														<FormControlLabel className={classes.chekcbox}
															control={<Checkbox color="primary" value={values.company} onChange={() => setFieldValue('company', !values.company)} />}
															label={tr('Company')}
														/>
													</FormGroup>
												</FormControl>
											</Grid>

											{values.company &&
												<>
													<Grid item xs={12} sm={6}>
														<TextField fullWidth variant="outlined" required={values.company} label={tr('Company')} {...props('companyName')} />
													</Grid>
													<Grid item xs={12} sm={6}>
														<TextField fullWidth variant="outlined" required={values.company} label={tr('Company code')} {...props('companyCode')} />
													</Grid>
													<Grid item xs={12} sm={6}>
														<TextField fullWidth variant="outlined" label={tr('Company address')} {...props('companyAddress')} />
													</Grid>
													<Grid item xs={12} sm={6}>
														<TextField fullWidth variant="outlined" label={tr('Company VAT code')} {...props('companyVATCode')} />
													</Grid>
												</>
											}

											<Grid item xs={12}>
												{tr('Have a discount code?')}
											</Grid>
											<Grid item xs={8}>
												<TextField fullWidth variant="outlined" label={tr('Discount code')} {...props('discountCode')} value={discountCode} onChange={handleDiscountCodeInputChange} />
											</Grid>
											<Grid item xs={4} style={{ textAlign: 'center', alignSelf: 'center' }}>
												<Button disabled={!discountCode} color="secondary" type="button" onClick={applyDiscountCode}>{tr('Apply')}</Button>
											</Grid>
											{isAppliedDiscountCodeValid === true && <Grid item xs={12}>
												<Alert>
													{tr('Discount code applied')}
												</Alert>
											</Grid>}
											{isAppliedDiscountCodeValid === false && <Grid item xs={12}>
												<Alert severity="error">
													{tr('It appears that this discount code is invalid.')}
												</Alert>
											</Grid>}
										</Grid>

										<FormControl component="fieldset" className={classes.aggrements}>
											<FormGroup>
												<FormControlLabel className={classes.chekcbox}
													control={<Checkbox onBlur={handleBlur} id="terms" color="primary" name="acceptsTermsConditions" checked={isAcceptTermsConditions} onChange={() => { handleAcceptTermsAndConditions(), setFieldValue('acceptsTermsConditions', !values.acceptsTermsConditions); }} />}
													label={(
														<>
															<label htmlFor="terms" className={`${classes.label} ${touched['acceptsTermsConditions'] && Boolean(errors['acceptsTermsConditions']) ? classes.error : ''}`} >
																{tr('I accept')}
																&nbsp;
																<Link to={`/terms/?language=${locale}`} className={classes.link}>{tr('Terms and Conditions')}</Link>
																&nbsp;
																{tr('and')}
																&nbsp;
																<Link to={`/privacy/?language=${locale}`} className={classes.link}>{tr('Privacy Policy')}</Link>
															</label>
															{touched['acceptsTermsConditions'] && Boolean(errors['acceptsTermsConditions']) &&
																<FormHelperText error={true}>{touched['acceptsTermsConditions'] ? errors['acceptsTermsConditions'] : ''}</FormHelperText>
															}
														</>
													)}
												/>
												<FormControlLabel className={classes.chekcbox}
													control={<Checkbox id="mailing" color="primary" value={values.agreeMarketingInfo} onChange={() => setFieldValue('agreeMarketingInfo', !values.agreeMarketingInfo)} />}
													label={(
														<label htmlFor="mailing" className={classes.label}>
															{tr('I agree to get marketing info. I may unsubscribe anytime.')}
														</label>
													)}
												/>
											</FormGroup>
										</FormControl>
										{booking && !booking.expired &&
											<Button wide={true} color="primary" type="submit">{tr('Pay')}</Button>
										}
										{booking && booking.expired &&
											<Alert variant="outlined" severity="error">
												{tr('Booking expired. Checkout is not available.')}
											</Alert>
										}
										<TermsAndConditionsConfirmDialog
											isOpen={isConfirmModalOpen}
											onClose={() => { setIsConfirmModalOpen(false); setFieldValue('acceptsTermsConditions', false); setIsAcceptTermsConditions(false); }}
											submitAction={() => { setIsConfirmModalOpen(false); setFieldValue('acceptsTermsConditions', true); setIsAcceptTermsConditions(true); }}
										/>
									</Form>
								);
							}
							}
						</Formik>
					</Grid>}

					{isAdditionalPaymentStepUsed && <Grid item xs={12} md={8} container spacing={2}>
						<Grid item xs={12}>
							<div id="adyen-dropin-container" />
						</Grid>
					</Grid>}

					<Grid item xs={12} md={4}>
						{booking && pricing &&
							<CheckoutSummary booking={booking} pricing={pricing} />
						}
					</Grid>
				</Grid>
			</Container>

			<Footer />

			{payseraForm}

			<Backdrop
				className={classes.backdrop}
				open={isBackdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}

ColivingCheckout.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
};

export default ColivingCheckout;

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		position: 'relative',
		minHeight: '96vh',
	},
	headline: {
		marginBottom: theme.spacing(3),
	},
	form: {
		marginBottom: theme.spacing(5),
	},
	payment: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	aggrements: {
		marginBottom: theme.spacing(3),
	},
	chekcbox: {
	},
	link: {
		color: 'inherit',
		borderBottom: '1px solid',
		borderBottomColor: 'inherit',
		textDecoration: 'none',

		'&:hover': {
			borderBottomColor: 'transparent',
		},
	},
	label: {
		color: theme.palette.primary.light,
		userSelect: 'none',
		cursor: 'pointer',

		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
	error: {
		color: theme.palette.error.main,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.custom.main,
		backgroundColor: 'rgba(202, 204, 203, 0.1)',
	},
}));

