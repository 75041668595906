/* eslint-disable react/prop-types */
import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ImageGallery from 'react-image-gallery';
import { GalleryLeftIcon, GalleryRightIcon } from '@common/components/Icon';

const MemoProductImages = memo(function MemoProductImages({ items, renderItem, renderThumbInner, renderLeftNav, renderRightNav }) {
	return (
		<ImageGallery
			items={items}
			showFullscreenButton={false}
			showPlayButton={false}
			useTranslate3D={false}
			showThumbnails={items.length > 1}
			renderItem={renderItem}
			renderThumbInner={renderThumbInner}
			renderLeftNav={renderLeftNav}
			renderRightNav={renderRightNav}
		/>
	);
});

function ProductImages({ images }) {
	const classes = useStyles();

	const items = useMemo(() => images.filter(i => !!i).map(i => ({
		original: `url(/images/${i})`,
		image: { backgroundImage: `url(/images/${i})`},
		thumbnailClass: classes.thumb,
	})) || [], [images]);

	const renderItem = useCallback((props) => <div className={`${classes.box} ${classes.image}`} style={props.image} />, []);

	const renderThumbInner = useCallback((props) => <div className={`${classes.box} ${classes.thumbInner}`} style={props.image} />, []);

	const renderLeftNav = useCallback((onClick, disabled) => <button className={`${classes.nav} ${classes.left}`} disabled={disabled} onClick={onClick}><GalleryLeftIcon /></button>, []);

	const renderRightNav = useCallback((onClick, disabled) => <button className={`${classes.nav} ${classes.right}`} disabled={disabled} onClick={onClick}><GalleryRightIcon /></button>, []);

	return (
		<div className={classes.container}>
			{items.length === 0 &&
				<div className={`${classes.box} ${classes.image}`} />
			}
			{items.length > 0 &&
				<MemoProductImages
					items={items}
					renderItem={renderItem}
					renderThumbInner={renderThumbInner}
					renderLeftNav={renderLeftNav}
					renderRightNav={renderRightNav}
				/>
			}
		</div>
	);
}

MemoProductImages.propTypes = {
	items: PropTypes.array,
	renderItem: PropTypes.func.isRequired,
	renderThumbInner: PropTypes.func.isRequired,
	renderRightNav: PropTypes.func.isRequired,
};

ProductImages.propTypes = {
	images: PropTypes.array,
};

export default ProductImages;

const useStyles = makeStyles(theme => ({
	container: {
		marginBottom: theme.spacing(5),
		[theme.breakpoints.up('md')]: {
			marginRight: theme.spacing(3),
		},
	},
	box: {
		backgroundColor: theme.palette.custom.background,
		borderRadius: 4,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	image: {
		height: '248px',

		[theme.breakpoints.up('md')]: {
			height: '490px',
		},
	},
	thumb: {
		border: 'none !important',
		marginTop: '3px',
		cursor: 'pointer',

		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.up('sm')]: {
			flex: '0 0 153px;',
			height: '104px',

			'&:nth-child(n+2)': {
				marginLeft: '8px',
			},
		},
		[theme.breakpoints.up('md')]: {
			flex: '0 0 229px;',
			height: '156px',
			marginTop: '11px',

			'&:nth-child(n+2)': {
				marginLeft: '16px',
			},
		},


	},
	thumbInner: {
		border: 'none !important',

		[theme.breakpoints.up('sm')]: {
			width: '153px;',
			height: '104px',
		},
		[theme.breakpoints.up('md')]: {
			width: '229px;',
			height: '156px',
		},
	},
	nav: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		top: '50%',
		transform: 'translateY(-50%)',
		width: '36px',
		height: '36px',
		border: 'none',
		borderRadius: '50%',
		background: 'rgba(255, 255, 255, .65)',
		zIndex: 50,
		cursor: 'pointer',
		outline: 'none',

		[theme.breakpoints.up('md')]: {
			width: '48px',
			height: '48px',
		},

		'&:hover': {
			background: '#fff',
		},
	},
	right: {
		right: '12px',
	},
	left: {
		left: '12px',
	},
}));
