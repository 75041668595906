import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Typography, CardContent, CardActions } from '@material-ui/core';

import { Button } from '@common/components';
import { useTranslation } from '@common/utils';
import { useSettings } from '@common/hooks';

const ServiceCardProps = ({ personCapacity }) => {
	const classes = useStyles();
	const tr = useTranslation();

	return (
		<Typography variant="body2" className={classes.props}>
			{personCapacity && <span className={classes.prop}>{personCapacity} {tr(personCapacity > 1 ? 'guests' : 'guest')}</span>}
			<span className={classes.prop}>{tr('access to coworking')}</span>
		</Typography>
	);
};

const ServiceCardPrice = ({ pricePerDay }) => {
	const classes = useStyles();
	const tr = useTranslation();
	const { currencySymbol } = useSettings();

	return (
		<Typography variant="body2" className={classes.price}>
			{currencySymbol}{pricePerDay}
			<span className={classes.currency}> {`/${tr('day')}`}</span>
		</Typography>
	);
};

function ServiceCard({ service }) {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			{service.image && <CardMedia className={classes.media} image={`/images/${service.image}`} title="" />}
			{!service.image && <div className={classes.media} />}
			<CardContent className={classes.content}>
				<div className={classes.aside}>
					<ServiceCardPrice pricePerDay={service.pricePerDay} />
				</div>
				<Typography gutterBottom variant="h3" component="h3">
					{service.title}
				</Typography>
				<Typography variant="body2" component="p">
					{service.description}
				</Typography>
			</CardContent>
			<CardActions className={classes.actions}>
				<Button color="dark" style={{ minWidth: '180px' }}>Add</Button>
			</CardActions>
		</Card>
	);
}

ServiceCard.propTypes = {
	service: PropTypes.object.isRequired,
};

ServiceCardProps.propTypes = {
	personCapacity: PropTypes.number,
};

ServiceCardPrice.propTypes = {
	pricePerDay: PropTypes.number,
};

export default ServiceCard;

const useStyles = makeStyles(theme => ({
	card: {
		borderRadius: '4px',
		border: `1px solid ${theme.palette.custom.borders}`,
		boxShadow: 'none',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
	},
	aside: {
		flex: '0 0 auto',
	},
	content: {
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
	},
	actions: {
		padding: '0 24px 24px',
	},
	media: {
		height: '248px',
		backgroundColor: theme.palette.custom.background,
	},
	props: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: theme.spacing(2),
	},
	prop: {
		padding: `5px ${theme.spacing(2)}px`,
		border: `1px solid ${theme.palette.custom.borders}`,
		color: theme.palette.primary.light,
		borderRadius: '18px',
		whiteSpace: 'nowrap',
		fontSize: '14px',

		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%',
			overflow: 'hidden',
			textOverflow: 'hidden',
		},

		'&:nth-child(n+2)': {
			[theme.breakpoints.down('sm')]: {
				marginTop: theme.spacing(1),
			},
			[theme.breakpoints.up('md')]: {
				marginLeft: theme.spacing(2),
			},
		},
	},
	price: {
		lineHeight: '2.1rem',
	},
	currency: {
		color: theme.palette.primary.light,
		paddingLeft: '4px',
	},
}));
