import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

function ColivingDetail({ header, children }) {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Typography variant="h3" className={classes.header}>
				{header}
			</Typography>
			<Typography variant="body2" component="div">
				{children}
			</Typography>
		</div>
	);
}

ColivingDetail.propTypes = {
	header: PropTypes.string,
	children: PropTypes.node,
};

export default ColivingDetail;

const useStyles = makeStyles(theme => ({
	container: {
		marginBottom: theme.spacing(5),
	},
	header: {
		marginBottom: theme.spacing(2),
	},
}));
