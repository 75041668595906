export default {
	'Search': 'Ieškoti',
	'Check in': 'Atvykimas',
	'Select Date': 'Pasirinkite datą',
	'Check Out': 'Išvykimas',
	'Terms and Conditions': ' Sąlygos ir Nuostatos',
	'Privacy Policy': 'Privatumo politika',
	'Book a co-working space': 'Užsisakykite darbo vietą',
	'day': 'diena',
	'Days': 'Dienos',
	'period': 'periodas',
	'week': 'savaitė',
	'weeks': 'savaitės',
	'month': 'mėnuo',
	'months': 'mėnesiai',
	'hour': 'valanda',
	'Hours': 'Valandos',
	'hours': 'valandos',
	'days': 'dienos',
	'guests': 'svečių', // eg "100 guests"
	'access to coworking': 'prieiga prie darbo vietų',
	'Description': 'Aprašymas',
	'Free cancellation': 'Nemokamas atšaukimas',
	'Location': 'Lokacija',
	'Add dates for prices': 'Pasirinkite datas',
	'Total': 'Iš viso',
	'Inquire': 'Teirautis',
	'Book Now': 'Užsakyti',
	'Room': 'Kambarys',
	'Request for availability': 'Prašymas dėl prieinamumo',
	'Please fill contact form': 'Prašome užpildyti kontaktinę formą',
	'Refine your booking': 'Patikslinkite rezervaciją',
	'Your booking details': 'Jūsų krepšelio apžvalga',
	'Booking summary': 'Krepšelio apžvalga',
	'Checkout': 'Apmokėjimas',
	'Need more rooms?': 'Reikia daugiau kambarių?',
	'Need more products?': 'Reikia daugiau prekių?',
	'Add Room': 'Pridėti kambarį',
	'Add room': 'Pridėti kambarį',
	'Add product': 'Pridėti prekę',
	'We will hold your reservation until you add all rooms you need.': 'Išsaugosime jūsų rezervaciją, kol pridėsite visus jums reikalingus kambarius.',
	'We will hold your reservation until you add all the products you need.': 'Mes laikysime jūsų rezervaciją, kol pridėsite visas jums reikalingas prekes.',
	'Summary': 'Apžvalga',
	'Services': 'Paslaugos',
	'Proceed to checkout': 'Pereikite prie apmokėjimo',
	'Check-in': 'Atvykimas',
	'Check-out': 'Išvykimas',
	'Rooms': 'Kambariai',
	'room': 'Kambarys',
	'Quantity': 'Kiekis',
	'Price (taxes incl.)': 'Kaina (su mokesčiais)',
	'remove reservation': 'Pašalinti rezervaciją',
	'Contact information': 'Kontaktinė informacija',
	'Name': 'Vardas',
	'Last name': 'Pavardė',
	'Email': 'El. paštas',
	'Your phone': 'Jūsų telefonas',
	'Company': 'Įmonė',
	'Company code': 'Įmonės kodas',
	'Company address': 'Įmonės adresas',
	'Company VAT code': 'Įmonės PVM kodas',
	'Have a discount code?': 'Turite nuolaidos kodą?',
	'Discount code': 'Nuolaidos kodas',
	'Apply': 'Taikyti',
	'I accept': 'Aš sutinku',
	'and': 'ir',
	'I agree to get marketing info. I may unsubscribe anytime.': 'Sutinku gauti rinkodaros informaciją. Galiu bet kada atsisakyti prenumeratos.',
	'Pay': 'Mokėti',
	'Booking details': 'Rezervacijos informacija',
	'VAT': 'PVM',
	'Total (taxes incl.)': 'Iš viso (su mokesčiais)',
	'Check-Out': 'Išsiregistruoti',
	'Required': 'Privalomas',
	'Must be an email': 'Turi būti El. paštas',
	'It appears that this discount code is invalid.': 'Pasirodo, kad šis nuolaidos kodas neteisingas.',
	'Discount code applied': 'Nuolaidos kodas pritaikytas',
	'It is not available during selected dates': 'nepasiekiama pasirinktomis dienomis',
	'Request': 'Prašymas',
	'Failed reservation': 'Nepavyko rezervuoti',
	'There was a problem and we were not able to complete your reservation order. Possible reasons why order failed are:': 'Iškilo problema ir mes negalėjome įvykdyti jūsų rezervacijos užsakymo. Galimos priežastys, kodėl nepavyko užsakyti:',
	'a billing error caused by your bank': 'Atsiskaitymo klaida, kurią sukėlė jūsų bankas',
	'insufficient credit on your account': 'Nepakankamas kreditas jūsų sąskaitoje',
	'your credit card is expired': 'Baigėsi jūsų kredito kortelės galiojimo laikas',
	'booking was cancelled or expired': 'užsakymas buvo atšauktas arba pasibaigęs',
	'Your booking reservation': 'Jūsų užsakymo rezervacija',
	'Booking confirmation was sent to you by email at': 'Užsakymo patvirtinimas buvo išsiųstas jums el. paštu',
	'Hello there,': 'Sveiki,',
	'Thank you for booking with us. We are looking forward to welcoming you.': 'Dėkojame, kad užsisakėte pas mus. Nekantriai laukiame jūsų.',
	'If you have any questions or preferences for your stay please get in touch': 'Jei turite klausimų ar pageidavimų dėl viešnagės, susisiekite',
	'Your payment failed': 'Jūsų mokėjimas nepavyko',
	'Unfortunately, we couldn\'t collect payment on your purchase. Please take a moment to review your billing information - if it looks correct, please': 'Deja, negalime priimti mokėjimo už pirkinį. Skirkite šiek tiek laiko ir peržiūrėkite atsiskaitymo informaciją, jei ji atrodo teisinga',
	'to let us know. Otherwise, please update your information': 'Praneškite mums. Kitu atveju atnaujinkite savo informaciją',
	'f you have any questions about your checkout,': 'jei turite klausimų dėl atsiskaitymo,',
	'and we\'ll get right back to you.': 'ir mes tuoj su jumis susisieksime.',
	'Upon arrival': 'Atvykus',
	'here to find us': 'mus galite rasti',
	'There are plenty of public parking places at the location.': 'Vietoje yra daug viešų automobilių stovėjimo vietų.',
	'NotFound': 'Resursas nerastas',
	'BookingOneOrMoreProductsUnavailable': 'Nėra laisvų lovų kambaryje',
	'CoworkingOrConferenceRoomBookingOneOrMoreProductsUnavailable': 'Nėra laisvų kambarių',
	'UnexpectedError': 'Oi, susipainiojome. Labai apgailestaujame, bet kartais viskas tiesiog griūna. Galbūt norėsite pabandyti dar kartą. Prašau? :)',
	'DuplicateOrder': 'Atrodo, kad jūsų užsakymas jau pateiktas! Nenorime apmokestinti daugiau nei jau užsisakėte :)',
	'BookingExpired': 'Panašu, kad jūsų užsakymas baigėsi, pradėkite iš naujo.',
	'IncorrectBookingCheckOutDate': 'Neteisinga užsakymo išsiregistravimo data.',
	'Amenities': 'Patogumai',
	'persons': 'vietos',
};
