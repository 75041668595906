import React from 'react';

import { SvgIcon } from '@material-ui/core';

function Clear(props) {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M5,20.976h1V9.001H5V20.976z M8,20.976h1V9.001H8V20.976z M11,20.976h1V9.001h-1V20.976z M14,20.976h1V9.001h-1V20.976z M19,6.037h-1H2H1V3.542c0-0.275,0.225-0.499,0.5-0.499h17c0.275,0,0.5,0.224,0.5,0.499V6.037z M17,21.505c0,0.825-0.673,1.497-1.5,1.497h-11c-0.827,0-1.5-0.672-1.5-1.497V7.035h14V21.505z M5,1.497 c0-0.275,0.225-0.499,0.5-0.499h9c0.275,0,0.5,0.224,0.5,0.499v0.499H5V1.497z M20,3.542c0-0.825-0.673-1.497-1.5-1.497H16V1.497 C16,0.672,15.327,0,14.5,0h-9C4.673,0,4,0.672,4,1.497v0.548H1.5C0.673,2.045,0,2.716,0,3.542v3.493h2v14.47 C2,22.881,3.121,24,4.5,24h11c1.379,0,2.5-1.119,2.5-2.495V7.035h2V3.542z"
			/>
		</SvgIcon>
	);
}

export default Clear;
