import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import { useTranslation } from '@common/utils';

import { DatePickers } from '@components/Coliving';

function DateFilter({ filter, setFilter }) {
	const classes = useStyles();
	const [local, setLocal] = useState(filter);
	const tr = useTranslation();

	const handleSearch = () => {
		setFilter({
			...filter,
			...local,
		});
	};

	return (
		<div className={classes.component}>
			<DatePickers dates={local} setDates={setLocal} header={true}/>
			<Button variant="contained" color="primary" className={classes.button} onClick={handleSearch}>{tr('Search')}</Button>
		</div>
	);
}

DateFilter.propTypes = {
	filter: PropTypes.object.isRequired,
	setFilter: PropTypes.func.isRequired,
};

export default DateFilter;

const useStyles = makeStyles(theme => ({
	component: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			flexDirection: 'row',
		},
	},
	button: {
		backgroundColor: '#000091',
		color: '#fff',
		padding: '8px 32px',
		textTransform: 'initial',
		fontSize: '16px',
		boxShadow: 'none',

		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			marginLeft: '48px',
			borderRadius: '32px',
		},

		'&:hover': {
			boxShadow: 'none',
		},
	},
}));
