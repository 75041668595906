import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Router from '../Router/Router';

function Layout() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Router />
		</div>
	);
}

export default Layout;

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
	},
}));
