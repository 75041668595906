import { combineReducers } from 'redux';
// import session from './sessionReducer';
import booking from './bookingReducer';
import filter from './filterReducer';
import settings from './settingsReducer';
import locale from './localeReducer';

export default combineReducers({
	// session,
	booking,
	filter,
	settings,
	locale,
});
