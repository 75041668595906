import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Typography, Radio } from '@material-ui/core';

import { useTranslation, useI18n } from '@common/utils';
import { useSettings } from '@common/hooks';
import { Link } from 'react-router-dom';

import {
	QuantitySelector,
} from '@components/Coliving';

const Option = ({ title, checked }) => {
	const classes = useStyles();

	return (
		<div className={classes.option}>
			<Radio color="primary" checked={checked} disabled={!checked} className={classes.radio} />
			{title}
		</div>
	);
};

const getOption = (id, diff, title, personCapacity, checked, available, isDorm, search, assetCapacity, tr) => {
	return {
		diff,
		title: `${title}${isDorm ? '' : ` (${tr('persons')}: ${personCapacity || assetCapacity})`}`,
		personCapacity,
		checked,
		available,
		to: available ? `/${id}${search ? `?${search}` : ''}` : '',
	};
};

function ProductOptions({ coliving, search = '', quantity, setQuantity }) {
	const classes = useStyles();
	const tr = useTranslation();
	const i18n = useI18n(coliving.i18n);
	const title = i18n('title', coliving.title);

	const [options, setOptions] = useState([]);

	const { currencySymbol } = useSettings();

	const { locale } = useSelector(state => state.locale);

	const isDorm = coliving.assetType === 'dorm';

	useEffect(() => {
		const props = (coliving.options || []).map(option => {
			const total = option.total - coliving.total || 0;
			const diff = total === 0 ? '' : `${total > 0 ? '+' : ''}${total} ${currencySymbol}`;

			return getOption(option._id, diff, option?.i18n[locale]?.title || option.title, option.personCapacity, false, option.available !== false, option.assetType === 'dorm', search, coliving.assetCapacity, tr);
		});

		setOptions([...props, getOption(coliving._id, '', title, coliving.personCapacity, true, coliving.available !== false, coliving.assetType === 'dorm', search, coliving.assetCapacity, tr)]);
	}, [coliving, locale]);

	return (
		<>
			<Typography variant="h5" className={classes.label}>{tr(isDorm ? 'Bed' : 'Room')}</Typography>

			{options.sort((a, b) => b.personCapacity - a.personCapacity).map((option, i) => {
				return (
					<div key={`option-${i}`} >
						{option.available && option.to &&
							<div className={`${classes.option}`}>
								<Link className={classes.link} to={option.to}>
									<Option checked={option.checked} title={option.title} />
								</Link>
								{option.diff !== '' &&
									<div className={classes.aside}>{option.diff}</div>
								}
								{isDorm && option.checked &&
									<div className={classes.aside}>
										<QuantitySelector quantity={quantity} max={coliving.assetCapacity} handleChange={(quantity) => setQuantity(quantity)} />
									</div>
								}
							</div>
						}
						{!option.available &&
							<div className={`${classes.option} ${classes.disabled}`}>
								<Option checked={option.checked} title={option.title} />
								{option.diff &&
									<div className={classes.aside}>{option.diff}</div>
								}
							</div>
						}
					</div>
				);
			})}
		</>
	);
}

Option.propTypes = {
	checked: PropTypes.bool,
	title: PropTypes.string,
	diff: PropTypes.string,
	children: PropTypes.node,
};

ProductOptions.propTypes = {
	coliving: PropTypes.object.isRequired,
	search: PropTypes.string,
	quantity: PropTypes.number.isRequired,
	setQuantity: PropTypes.func.isRequired,
};

export default ProductOptions;

const useStyles = makeStyles(theme => ({

	label: {
		color: theme.palette.primary.light,
		marginBottom: theme.spacing(1),
	},

	option: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		alignItems: 'center',
	},
	link: {
		cursor: 'pointer',
		color: theme.palette.primary.dark,
		textDecoration: 'none',
	},
	aside: {
		flex: '0 0 85px',
		textAlign: 'right',
	},
	radio: {
		pointerEvents: 'none',
		float: 'left',
		marginRight: '8px',
		padding: 0,
	},

	disabled: {
		opacity: 0.5,
		pointerEvents: 'none',
	},
}));
