import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';
import { changeLocale } from '@redux/actions/localeActions';
import { useSettings } from '@common/hooks';

const useStyles = makeStyles(() => ({
	'@global': {
		'.MuiOutlinedInput-notchedOutline': {
			border: '1px solid #EAEAEA',
		},
	},
}));

const LanguagePicker = ({ onChange, languages }) => {
	const { locale } = useSelector(state => state.locale);
	const classes = useStyles();
	const dispatch = useDispatch();
	const { defaultLanguage: defaultLocaleFromSettings } = useSettings();

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const language = urlParams.get('language');

		const defaultLocale = language || defaultLocaleFromSettings || 'en';

		dispatch(changeLocale(defaultLocale));
	}, [dispatch, defaultLocaleFromSettings]);

	return (
		<Select
			value={locale}
			onChange={onChange}
			variant="outlined"
			classes={classes}
			style={{ margin: '0px 5px' }}
		>
			{languages.map((lang, i) => (
				<MenuItem key={i} value={lang}>
					{lang.toUpperCase()}
				</MenuItem>
			))}
		</Select>
	);
};

LanguagePicker.propTypes = {
	onChange: PropTypes.func,
	languages: PropTypes.array.isRequired,
};

export default LanguagePicker;
