import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { Button, Typography, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';

import { Formik, Form } from 'formik';

import { useSettings } from '@common/hooks';

import { useTranslation } from '@common/utils';

function TermsAndConditionsConfirmDialog({ isOpen, onClose, submitAction }) {
	const classes = useStyles();
	const tr = useTranslation();

	const dialogContentRef = useRef(null);

	const {
		privacyPolicy,
		termsAndConditions,
	} = useSettings();

	const [isModalActionDisabled, setIsModalActionDisabled] = useState(true);

	const handleScroll = (dialogContent) => {
		if (dialogContent) {
			const contentHight = dialogContent.scrollHeight - dialogContent.scrollTop;
			const clientHeight = dialogContent.clientHeight;
			const isScrollAtBottom = clientHeight - contentHight >= -2;

			if (isScrollAtBottom) {
				setIsModalActionDisabled(false);
			}
		}
	};

	const handleCloseDialog = () => {
		onClose();
		setIsModalActionDisabled(true);
	};

	const WIDTH = window.innerWidth;

	return (

		<Formik>
			{() => {
				return (
					<Form>
						<Dialog
							open={isOpen}
							onClose={handleCloseDialog}
							aria-labelledby="form-dialog-title"
							fullWidth
							maxWidth={'lg'}>
							<DialogContent
								ref={(node) => {
									dialogContentRef.current = node;
									handleScroll(node);
								}}
								onScroll={() => handleScroll(dialogContentRef.current)}>

								<Grid container className={classes.container} style={WIDTH <= 768 ? { fontSize: '8px' } : {}}>
									{termsAndConditions ?

										<Grid item xs={12} style={{ paddingBottom: '100px' }} dangerouslySetInnerHTML={{ __html: termsAndConditions }} />

										:
										<Typography variant="h4" className={classes.headline}>
											{tr('Terms and Conditions')}
										</Typography>
									}

									{privacyPolicy ?
										<Grid item xs={12} style={{ paddingBottom: '100px' }} dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
										:
										<Typography variant="h4" className={classes.headline}>
											{tr('Privacy Policy')}
										</Typography>
									}
								</Grid>

							</DialogContent>
							<DialogActions style={{ padding: '16px 24px' }}>
								<Button
									onClose={handleCloseDialog}
									onClick={handleCloseDialog}
									variant="contained"
									className={classes.button}>
									{tr('Cancel')}
								</Button>
								<Button
									onClick={submitAction}
									className={classes.button} variant="contained"
									style={!isModalActionDisabled ? { backgroundColor: '#006CFF', borderColor: '#006CFF', color: '#fff' } : {}}
									disabled={isModalActionDisabled}
								>
									{tr('Accept')}
								</Button>
							</DialogActions>
						</Dialog>
					</Form>
				);
			}
			}
		</Formik>
	);
}

TermsAndConditionsConfirmDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	submitAction: PropTypes.func.isRequired,
};

export default TermsAndConditionsConfirmDialog;

const useStyles = makeStyles(theme => ({
	error: {
		color: theme.palette.error.main,
	},
	button: {
		padding: '8px 16px',
		minWidth: '100px',
	},
	container: {
		maxWidth: '100%',
		margin: '0 auto',
	},
}));

